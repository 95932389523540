import * as React from 'react';
import validator from 'validator';
import { escape } from './../../Util/validation/cleanInput';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import { getMe, getCoupon, createOrder } from '../../API/Http/Requests';
import DisplayAddress from './../DisplayAdress/DisplayAdress';
import CartProductCard from './Overview/NewCartProductCard';
import Button from '../CrossPlatform/Button/Button';
import Storage from './../CrossPlatform/Storage/Storage';
import { ICreateOrderOrder } from '../../store/actions/OrderProcessActions';
import { ICreateOrder } from '../../API/IDataService';
import CouponField from './Payment/CouponField';
import { Package } from '../../API/Interfaces/Bundle';
import { EURO } from '../../Util/currency';
// import configDefault from '../../config/config.selector';
import AmsterdamAfdelingen from './Payment/AmsterdamAfdelingen';
import ABenCGroepAfdelingen from './Payment/ABenCGroepAfdelingen';
import StantecVestigingen from './Payment/StantecVestigingen';
import RPSVestigingen from './Payment/RPSVestigingen';
import moment from 'moment';
import { routingName } from './../CrossPlatform/OrderProcess/NewOrderProcess.web';

// const conf = configDefault as any;

const findLocaleText = (flower: Package, key: string) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === key);
    if (locale && locale.value) {
        return locale.value;
    }
    if (key === 'title') {
        return flower.category;
    }
    return undefined;
}

export default class Payment extends React.Component<any> {
	timeout : any = 0; // De timeout voor het geven van feedback bij een foute coupon code.
	invalidCouponMessage: string = "Dit is een onbekende coupon code.";
	validCouponMessage: string = "Dit is een geldige coupon!";

	storage = new Storage;

	state = {
		selected: process.env.REACT_APP_NODE_ENV === "portal" && routingName !== "consumersite" ? 'credit' : 'ideal',
		coupon: '',
		validCoupon: false,

		submitting: false,

		couponMessage: undefined,
		errorMessage: undefined,
		countryWarning: undefined,

		inkooporder: undefined,
		afdeling: undefined,
		afdelingText : undefined,
		orderConfirmation: false,

		isCompany: false,
		isVerified: false,
		PO_number: undefined,
    }

	componentDidMount() {
		getMe().then((prof) => {
			this.setState({
				isCompany: prof.businesses.length > 0,
				isVerified: prof.businesses[0] ? prof.businesses[0].verified : false,
			})
		}).catch(() => this.props.navigation.navigate('overview'));
		this.props.store.setActiveStep('payment');
		GoogleAnalytics.getInstance().hit(this.props.navigation.state);

		if (process.env.REACT_APP_NODE_ENV === "portal" && routingName !== "consumersite" &&
			this.props.store.orderProcess.deliveryAddress.country !== 'NL') {
				this.setState({countryWarning: 'In het geval van een bestelling in het buitenland kan je per mail/telefonisch contact opnemen.'})
		}
		let afdeling: string = '';
		if (routingName === "amsterdam") {
			afdeling = AmsterdamAfdelingen[1];
		}
		if (routingName === "abencgroep") {
			afdeling = ABenCGroepAfdelingen[1];
		}
		if (routingName === "stantec") {
			afdeling = StantecVestigingen[1];
		}
		if (routingName === "rps") {
			afdeling = RPSVestigingen[1];
		}		
		if (afdeling !== '') {
			this.setState({ afdeling });
		}
	}

	nextPage() {
		let tmpState = Object.assign({}, this.state);
		tmpState = escape(tmpState);

		this.handleCoupon(tmpState.coupon, false);

		if (routingName === "amsterdam" || routingName === "abencgroep" || routingName === "stantec" || routingName === "rps") {
			if (this.state.afdeling === 'Overig' && !this.state.afdelingText) {
				this.setState({errorMessage: 'Het verplichte veld "Kostenplaats-/afdelings-/entiteits-/vestigingsnaam" ontbreekt.'});
				return;
			}

			if (this.state.inkooporder === undefined) {
				this.setState({errorMessage: 'Het verplichte veld "Inkooporder-/referentienummer" ontbreekt.'});
				return;
			}

			if (!/^[0-9]{1,10}$/g.test(this.state.inkooporder as any)) {
				this.setState({errorMessage: 'De waarde in het veld "Inkooporder-/referentienummer" is ongeldig. Deze kan enkel uit cijfers bestaan en heeft een maximale lengte van 10 karakters.'});
				return;
			}

			if (!this.state.orderConfirmation) {
				this.setState({errorMessage: 'Bevestig met de checkbox dat je de bestelling wilt plaatsen.'});
				return;
			}
		}

		// Make sure the user has one of the payment options selected.
		if(validator.isLength(tmpState.selected, {min: 1, max: 16})) {
			if(tmpState.selected === 'mastercard') tmpState.selected = 'creditcard';
			if(tmpState.selected === 'American Express') tmpState.selected = 'creditcard';
			this.props.store.updatePayment(tmpState.selected);
			this.props.store.updateCoupon(tmpState.coupon);
			this.props.store.setPONumber(tmpState.PO_number);
			this.onAgreement();
		}
	}

	async handleCoupon(coupon: string, showError: boolean = true) : Promise<void> {
        try {
			const originalTotal = parseFloat(this.props.store.orderProcess.originalPrice);
            let discount = await getCoupon(coupon, originalTotal, this.props.store.product.products);
			this.setState({validCoupon: discount.valid});

            if (discount.valid) {
				clearTimeout(this.timeout);
				this.renderValidCouponCode();
                this.props.store.setDiscount(discount.discount);
                this.props.store.setTotalPrice(discount.price);
            } else if (showError) {
				this.renderWrongCouponCodeText();
                this.props.store.setDiscount(0);
                this.props.store.setTotalPrice(originalTotal);
            }
		}
		catch(e) {
			console.log(e);
		}
    }

    calculateShippingCosts(): number {
		let price: number = 0;
		this.props.store.product.products.map(product => {
			if (product.flower.category === 'Plant' || product.flower.category === 'Overig') {
				price = 0;
			} else if (product.flower.packageIntname && product.flower.packageIntname.toLowerCase().indexOf('tulpen') > -1) {
				price = 4.99;
			} else if (this.props.store.app.config.variablePrices.deliveryCost) {
				price = this.props.store.app.config.variablePrices.deliveryCost;
			}
		});
		return price;
	}

	calculateFlowerPrice(): number {
		let price: number = 0;
		this.props.store.product.products.map( product => {
			if (product.flower.amount) {
				price += product.flower.price * product.flower.amount;
			} else {
				price += product.flower.price;
			}
		});
		return price;
	}

	calculateVasePrice(): number {
		let price: number = 0;
        this.props.store.product.products.map(product => {
			if (product.flower.vaas) {
                const staticVasePrice = this.props.store.app.config.variablePrices.vasePrice;
                const vasePrice = staticVasePrice ? staticVasePrice : product.flower.vasePrice;
                price += vasePrice * product.flower.amount;
			}
		});
		return price;
    }

    calculateCardPrice(): number {
        let price: number = 0;
        this.props.store.product.products.map(product => {
			if (product.flower.note && product.flower.wishcardCode && product.flower.wishcardCode != '' && product.flower.wishcardCode !== 'GC000') {
                if (routingName === "amsterdam") {
                    price += product.flower.wishcardCode !== 'GC001' ? 
					    2.5 * product.flower.amount : 
                        0;
                } else {
                    price += product.flower.wishcardCode !== 'GC001' ? 
					    2.5 * product.flower.amount :
                        1.5 * product.flower.amount;
                }
			}
		});
		return price;
	}
    
    calculateChocolonelyPrice(): number {
        let price: number = 0;
        this.props.store.product.products.map(product => {
			if (product.flower.chocolonelyCode && product.flower.chocolonelyCode != '') {
                price += 4 * product.flower.amount;
			}
		});
		return price;
	}

	calculateSpoedPrice(): number {
		let price: number = 0;
        this.props.store.product.products.map(product => {
            if (product.flower.delivery.sameDay) {
                price += 15 * product.flower.amount;
            }
		});
		return price;
    }

	/**
	 * This function calculates the total price of the order.
	 * The input is per flower type.
	 * @param  flowerPrice price of the total amount of a certain flower.
	 * @param  vasePrice   Price of a vase.
	 * etc.
	 * @return             The totalprice of all flowers + the price of the vases etc.
	 */
	calculateTotalPrice(): number {
        const flowerPrice = this.calculateFlowerPrice();
        const vasePrice = this.calculateVasePrice();
        const shippingCosts = this.calculateShippingCosts();
        const cardCost = this.calculateCardPrice();
		const spoedPrice = this.calculateSpoedPrice();
        const chocolonelyPrice = this.calculateChocolonelyPrice();
		if(flowerPrice < 0 || vasePrice < 0) {
			return 0;
		}
		let price : number = 0;
		price = flowerPrice + vasePrice + shippingCosts + cardCost + spoedPrice + chocolonelyPrice;
		return price;
    }

	checkCoupon() : void {
		this.handleCoupon(this.state.coupon);
	}

	setCoupon(coupon: string): void {
		this.setState({coupon});
	}

	renderWrongCouponCodeText() {
		clearTimeout(this.timeout);
		const displayMessage = () => {
			this.setState({couponMessage: <div style={{flexDirection: 'row', marginBottom: 21}}>
				<MakeText style={{color: '#de8080'}}>{this.invalidCouponMessage}</MakeText>
				<img src={'/images/close.svg'} style={{width: 20, height: 20, marginLeft: 5}} />
			</div>});
		}
		this.timeout = setTimeout(displayMessage, 900);
	}

	renderValidCouponCode() {
		this.setState({couponMessage: <div style={{flexDirection: 'row', marginBottom: 21}}>
			<MakeText style={{color: 'green'}}>{this.validCouponMessage}</MakeText>
			<img src={'/images/check.svg'} style={{width: 20, height: 20, marginLeft: 5}} />
		</div>});
	}

	/**
	 * This function is called when the user clicks agree.
	 * The function calls multible other functions in order to do the following
	 * things:
	 *
	 * 1. Use products from the LOCALSTORAGE to create a new object that will be
	 * send to the backend.
	 * 2. Set those products in the redux store. This is needed becouse the next
	 * function will use the entire orderProcess from the store to create the order.
	 * 3. Callback: The store function (setOrders) takes a callback, when this
	 * function is called, create the orders that will be send to the backend.
	 * 4. Send the orders to the backend.
	 */
	async onAgreement() : Promise<void> {
		this.setState({submitting: true});
		// 1. creating the products.
		const products = await this.createProductsList();
		// 2, 3. Store the products, call the callback.
		this.props.store.setOrders({products, callback: async (products: ICreateOrderOrder[]) => {
			// 3. Inside the callback now, create the Orders for sending.
			const order = this.createOrder(products, this.props.store.orderProcess.deliveryNote);
			// 4. Send the orders to the backend.
			try {
				let shop = routingName;
				await this.sendOrderToBackend(order, shop);
			} catch {
				this.setState({errorMessage: 'Bestelling plaatsen is mislukt.'})
			}
			this.setState({submitting: false});
		}});
	}

	async createProductsList() : Promise<ICreateOrderOrder[]> {
		const storedProducts = await this.storage.retrieveData('products'); 
		let products = storedProducts.map( product => {
			let flower = product.flower;
			let bundle = product.bundle;
			flower.delivery.ticket = flower.note;
			flower.delivery.vaseCode = flower.vaseCode;
			flower.delivery.wishcardCode = flower.wishcardCode;
			flower.delivery.chocolonelyCode = flower.chocolonelyCode;
			flower.delivery.bubblesCode = flower.bubblesCode;
			return {
				product: bundle.id,
				packageId: flower.id,
				delivery: flower.delivery,
				type: flower.type,
				amount: flower.amount ? flower.amount : 1,
				vase: flower.vaas,
			};
		});

		return products;
	}

	// This is the order we will send to the backend.
	createOrder(orders: ICreateOrderOrder[], note: string) : ICreateOrder {

		let orderProcess = this.props.store.orderProcess;
		let busOrder = false;
		if (this.props.store.profile.businesses) {
			if (this.props.store.profile.businesses.length > 0) {
				busOrder = true;
			}
		}
		// The PO number is put inside the payment object.
		orderProcess.payment['PO'] = orderProcess.po_number;

		let order = {
			billingAddress: orderProcess.billingAddress.id,
			deliveryAddress: orderProcess.deliveryAddress.id,
			orders,
			payment: orderProcess.payment,
			note: note,
			business: busOrder,
			afdeling: this.state.afdeling === 'Overig' ? this.state.afdelingText : this.state.afdeling,
			inkooporder: this.state.inkooporder,
			totalPrice: this.props.store.orderProcess.totalPrice,
			flowerPrice: this.calculateFlowerPrice(), // incl tax
		};

		if (orderProcess.deliveryAddress.tav) {
			order['tav'] = orderProcess.deliveryAddress.tav;
		}

		return order;
	}

	async sendOrderToBackend(order: ICreateOrder, shop: string) {
		// await createOrder(order);
		const res = await createOrder(order, shop);
		return window.location = res.paymentUrl;

	}

    render() {
		const mobileView = this.props.mobileView;
		// If not all data that we wish to display is present, return the user to the overview screen.
		if(this.props.store.orderProcess.deliveryAddress.id === 0) {
			this.props.navigation.navigate('overview');
			return null;
		}

		const publicDeliveryAfter5 = process.env.REACT_APP_NODE_ENV === "portal" &&
			this.props.store.orderProcess.deliveryAddress.isPublicLocation &&
			this.props.store.product.products.find(p =>{
				const winEnd = p.flower.delivery.windowEnd;
				return moment(winEnd).isAfter(moment(winEnd).hour(17));
			});

        return (
            <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-around', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : undefined, marginTop: mobileView ? 25 : 0}}>
                    <MakeText style={{fontSize: mobileView ? 24 : 36, marginBottom: 15, alignSelf: 'center'}} fontFamily='Arno Pro'>Overzicht</MakeText>
                    <div style={{height: mobileView ? undefined : 320, width: mobileView ? '95%' : 750, marginLeft: mobileView ? '2.5%' : undefined, overflowY: 'auto', overflowX: mobileView ? undefined : 'clip'}}>
                        {this.props.store.product.products.map((p, i) => <CartProductCard key={p.id + '-' + i} package={p.flower} staticVasePrice={this.props.store.app.config.variablePrices.vasePrice} cardPrice={this.calculateCardPrice()} chocolonelyPrice={this.calculateChocolonelyPrice()} mobileView={mobileView} navigation={this.props.navigation} />)}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: mobileView ? '90%' : undefined, paddingLeft: mobileView ? '5%' : undefined, paddingRight: mobileView ? '5%' : undefined, marginTop: 15}}>
                        <div style={{display: 'flex', flexDirection: 'column', marginRight: 25, marginBottom: mobileView ? 15 : 0}}>
							<div style={{display: 'flex', flexDirection: 'row', marginBottom: 5}}>
								<MakeText style={{fontSize: 18}}>Bezorgadres</MakeText>
							</div>
                            <DisplayAddress
                                company={this.props.store.profile.business && this.props.store.profile.business.company}
                                firstname={this.props.store.orderProcess.deliveryAddress.fname}
                                lastname={this.props.store.orderProcess.deliveryAddress.lname}
                                street={this.props.store.orderProcess.deliveryAddress.street}
                                housenumber={this.props.store.orderProcess.deliveryAddress.housenumber}
                                addition={this.props.store.orderProcess.deliveryAddress.addition}
                                postalcode={this.props.store.orderProcess.deliveryAddress.postalcode}
                                city={unescape(this.props.store.orderProcess.deliveryAddress.city).replace('&#x27;', "'")}
								tav={this.props.store.orderProcess.deliveryAddress.tav}
                            />
                        </div>
						{/* routingName === 'consumer' && 
							<div style={{display: 'flex', flexDirection: 'column', marginLeft: 25, marginRight: 25, marginBottom: mobileView ? 15 : 0}}>
								<div style={{display: 'flex', flexDirection: 'row', marginBottom: 5}}>
									<MakeText style={{fontSize: 18}}>Factuuradres</MakeText>
								</div>
								<DisplayAddress
									company={(this.props.store.profile.business && this.props.store.profile.business.company) || this.props.store.orderProcess.billingAddress.company}
									firstname={this.props.store.orderProcess.billingAddress.fname}
									lastname={this.props.store.orderProcess.billingAddress.lname}
									street={this.props.store.orderProcess.billingAddress.street}
									housenumber={this.props.store.orderProcess.billingAddress.housenumber}
									addition={this.props.store.orderProcess.billingAddress.addition}
									postalcode={this.props.store.orderProcess.billingAddress.postalcode}
									city={unescape(this.props.store.orderProcess.billingAddress.city).replace('&#x27;', "'")}
								/>
							</div>
						*/}
						{routingName === 'consumersite' && 
							<div style={{display: 'flex', flexDirection: 'row', marginBottom: 5}}>
								<MakeText style={{fontSize: 16, fontStyle: 'italic'}}>Facturen zijn mogelijk op aanvraag (na betaling) via facturen@theflowerfamily.nl o.v.v.    "reeds betaald" + het ordernummer</MakeText>
							</div>
						}
						{this.props.store.orderProcess.deliveryNote ?
							<div style={{display: 'flex', flexDirection: 'column', width: 300, height: 93}}>
								<div style={{display: 'flex', flexDirection: 'row', marginBottom: 5}}>
									<MakeText style={{fontSize: 18}}>Bezorgopmerking</MakeText>
								</div>
								<div>
									<MakeText>
										{this.props.store.orderProcess.deliveryNote.substring(0, 99)}{this.props.store.orderProcess.deliveryNote.length > 100 ? '...' : ''}
									</MakeText>
								</div>
							</div> : undefined
						}
                    </div>
					<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', width: mobileView ? '90%' : 700, paddingLeft: mobileView ? '5%' : undefined, paddingRight: mobileView ? '5%' : undefined, justifyContent: 'space-around', marginTop: 30}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
							{this.props.store.product.products.map((product, i) => (
								<div key={i} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5}}>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, marginRight: 10}}>{findLocaleText(product.flower, 'title')}:</MakeText>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(product.flower.price * product.flower.amount)}</MakeText>
								</div>
							))}
                            
                            { this.props.store.product.products.find(p => p.category === 'E-Flora' || p.isFlowers == 1) &&
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}>Vaas:</MakeText>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateVasePrice())}</MakeText>
								</div>
							}
                                
							{ routingName !== 'amsterdam' &&
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}>Chocolonely:</MakeText>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateChocolonelyPrice())}</MakeText>
								</div>
							}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: mobileView ? 5 : 0}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}>Wenskaartje:</MakeText>
                                <MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateCardPrice())}</MakeText>
                            </div>
							{ routingName !== 'amsterdam' &&
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5}}>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, marginRight: 10}}>Bezorgkosten:</MakeText>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateShippingCosts())}</MakeText>
								</div>
							}
							{mobileView ?
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, marginRight: 10, fontWeight: 'bold'}}>Totaal incl. BTW: </MakeText>
									{this.props.store.orderProcess.originalPrice !== this.props.store.orderProcess.totalPrice ?
										<div>
											<MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}><del>{EURO(this.props.store.orderProcess.originalPrice)}</del></MakeText>
											<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold', marginLeft: 10}}>{EURO(this.props.store.orderProcess.totalPrice)}</MakeText>
										</div> :
										<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateTotalPrice())}</MakeText>
									}
								</div> :
								process.env.REACT_APP_NODE_ENV === "portal" &&
									<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
										<MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}>Totaal ex. BTW:</MakeText>
										<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateTotalPrice() / 1.09)}</MakeText>
									</div>
							}
							{!mobileView ?
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
									<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, marginRight: 10, fontWeight: 'bold'}}>Totaal incl. BTW: </MakeText>
									{this.props.store.orderProcess.originalPrice !== this.props.store.orderProcess.totalPrice ?
										<div>
											<MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}><del>{EURO(this.props.store.orderProcess.originalPrice)}</del></MakeText>
											<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold', marginLeft: 10}}>{EURO(this.props.store.orderProcess.totalPrice)}</MakeText>
										</div> :
										<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateTotalPrice())}</MakeText>
									}
								</div>
								: process.env.REACT_APP_NODE_ENV === "portal" &&
									<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
										<MakeText fontFamily={'Open Sans'} style={{fontSize: 15}}>Totaal ex. BTW:</MakeText>
										<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(this.calculateTotalPrice() / 1.09)}</MakeText>
									</div>
							}
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' : 380, paddingLeft: mobileView ? '5%' : undefined, paddingRight: mobileView ? '5%' : undefined, marginTop: mobileView ? 50 : 0}}>
                    <MakeText style={{fontSize: mobileView ? 24 : 36, marginBottom: 15, alignSelf: 'center'}} fontFamily='Arno Pro'>Betaalwijze</MakeText>
                    <div style={{display: 'flex', flexDirection: 'column', height: mobileView ? undefined : 400, backgroundColor: 'rgba(196,196,196,0.2)', padding: 15}}>
						{ routingName === "consumersite" && [
							<div key={1} style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
								<div style={{width: 80, display: 'flex', justifyContent: 'center'}}>
									<img src={'/images/iDeal.svg'} style={{width: 33, height: 29.3}} />
								</div>
								<input type='radio' name='payment' checked={this.state.selected === 'ideal'} value='iDEAL' onChange={() => this.setState({selected: 'ideal'})}></input>
								<MakeText fontFamily='Open Sans' style={{fontSize: 15, marginLeft: 10}}>iDEAL</MakeText>
							</div>,
							<div key={2} style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
								<div style={{width: 80, display: 'flex', justifyContent: 'center'}}>
									<img src={'/images/visa.svg'} style={{width: 43.3, height: 22.6}} />
								</div>
								<input type='radio' name='payment' checked={this.state.selected === 'creditcard'} value='creditcard' onChange={() => this.setState({selected: 'creditcard'})}></input>
								<MakeText fontFamily='Open Sans' style={{fontSize: 15, marginLeft: 10}}>Visa</MakeText>
							</div>,
							<div key={3} style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
								<div style={{width: 80, display: 'flex', justifyContent: 'center'}}>
									<img src={'/images/american_express.jpg'} style={{width: 80, height:  25.3, alignSelf: 'center'}} />
								</div>
								<input type='radio' name='payment' checked={this.state.selected === 'American Express'} value='American Express' onChange={() => this.setState({selected: 'American Express'})}></input>
								<MakeText fontFamily='Open Sans' style={{fontSize: 15, marginLeft: 10}}>American Express</MakeText>
							</div>,
							<div key={4} style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
								<div style={{width: 80, display: 'flex', justifyContent: 'center'}}>
									<img src={'/images/mc.svg'} style={{width: 33.2, height: 24}} />
								</div>
								<input type='radio' name='payment' checked={this.state.selected === 'mastercard'} value='mastercard' onChange={() => this.setState({selected: 'mastercard'})}></input>
								<MakeText fontFamily='Open Sans' style={{fontSize: 15, marginLeft: 10}}>Mastercard</MakeText>
							</div>,
						]}
						{ process.env.REACT_APP_NODE_ENV === "portal" && routingName !== "consumersite" &&
							<div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
								{routingName !== 'amsterdam' &&
									<input type='radio' name='payment' checked={this.state.selected === 'credit'} value='credit' onChange={() => this.setState({selected: 'credit'})}></input>
								}
							</div>
						}

						{  routingName === "consumersite" &&
							<CouponField coupon={this.state.coupon} setCoupon={(c: string) => this.setCoupon(c)} checkCoupon={() => this.checkCoupon()} />
						}
						{this.state.couponMessage}
						{ process.env.REACT_APP_NODE_ENV === "portal" && routingName !== "consumersite" &&
							<div>
								<div className='column mb-3'>
									{ routingName === 'amsterdam' || routingName === 'abencgroep' &&
										<MakeText fontFamily='Open Sans' style={{fontSize: 15}}>Kostenplaats-/afdelings-/entiteitsnaam*</MakeText>
									}

									{ routingName === 'stantec' || routingName === "rps" &&
										<MakeText fontFamily='Open Sans' style={{fontSize: 15}}>Vestigingsnaam*</MakeText>
									}

									{ routingName === 'amsterdam' &&
										<select className='smallText selectStyle' style={{width: '100%'}} value={this.state.afdeling} onChange={(e) => this.setState({afdeling: e.target.value})}>
											{AmsterdamAfdelingen.map(o => (
												<option value={o}>{o}</option>
											))}
										</select>
									}
									{ routingName === 'abencgroep' &&
										<select className='smallText selectStyle' style={{width: '100%'}} value={this.state.afdeling} onChange={(e) => this.setState({afdeling: e.target.value})}>
											{ABenCGroepAfdelingen.map(o => (
												<option value={o}>{o}</option>
											))}
										</select>
									}
									{ routingName === 'stantec' &&
										<select className='smallText selectStyle' style={{width: '100%'}} value={this.state.afdeling} onChange={(e) => this.setState({afdeling: e.target.value})}>
											{StantecVestigingen.map(o => (
												<option value={o}>{o}</option>
											))}
										</select>
									}
									{ routingName === 'rps' &&
										<select className='smallText selectStyle' style={{width: '100%'}} value={this.state.afdeling} onChange={(e) => this.setState({afdeling: e.target.value})}>
											{RPSVestigingen.map(o => (
												<option value={o}>{o}</option>
											))}
										</select>
									}

									{this.state.afdeling === 'Overig' &&
										<MakeText fontFamily='Open Sans' style={{fontSize: 15}}>Kostenplaats-/afdelings-/entiteitsnaam</MakeText>
									}
									
									{this.state.afdeling === 'Overig' && (routingName !== 'amsterdam' && routingName !== 'abencgroep' && routingName !== 'stantec' && routingName !== 'rps') &&
										<input type="text" className='mt-2' style={{fontSize: 15, padding: 5, width: '90%'}} value={this.state.afdelingText} onChange={(e) => this.setState({afdelingText: e.currentTarget.value})}></input>
									}
								</div>
								<div>
									{ routingName !== 'stantec' && routingName !== 'rps' ? 
										<div className='column mb-3 mt-2'>
											<MakeText fontFamily='Open Sans' style={{fontSize: 15}}>Inkooporder-/referentienummer*</MakeText>
											<input type="text" style={{fontSize: 15, padding: 5, width: '90%'}} value={this.state.inkooporder} onChange={(e) => this.setState({inkooporder: e.currentTarget.value})}></input>
										</div> :
										<div className='column mb-3 mt-2'>
											<MakeText fontFamily='Open Sans' style={{fontSize: 15}}>{`Inkooporder-/referentienummer (een cijfer)*`}</MakeText>
											<br/>
											<MakeText fontFamily='Open Sans' style={{fontSize: 13}}>Indien niet nodig vul een '0' in.</MakeText>
											<br/>
											<MakeText fontFamily='Open Sans' style={{fontSize: 13, color: 'grey'}}>NB: voor de ontvangernaam gebruik in stap 3 'Bestemd voor' i.p.v. dit veld.</MakeText>
											<input type="text" style={{fontSize: 15, padding: 5, width: '90%'}} value={this.state.inkooporder} onChange={(e) => this.setState({inkooporder: e.currentTarget.value})}></input>
										</div>
									}
								</div>
							</div>
						}

						{ process.env.REACT_APP_NODE_ENV === "portal" && routingName !== "consumersite" &&
							<div className='row alignCenter mb-3'>
								<MakeText style={{fontSize: 15}} fontFamily='Open Sans'>Velden met * zijn verplicht.</MakeText>
							</div>
						}

						{ process.env.REACT_APP_NODE_ENV === "portal" && routingName !== "consumersite" &&
							<div className='row alignCenter'>
								<input type="checkbox" id="confirm" name="confirm" style={{marginLeft: 5, marginRight: 5}} onChange={(e) => this.setState({orderConfirmation: e.currentTarget.checked})} checked={this.state.orderConfirmation} />
								<MakeText style={{ fontSize: 15 }} fontFamily='Open Sans'>{ routingName !== "stantec" && routingName !== "rps" ?
									'Kosten die bij deze opdracht horen, worden gefactureerd op het opgegeven referentie/inkoopordernummer. Besteller verklaart hierbij akkoord te gaan met bovenstaande voorwaarde.' :
									'Kosten die bij deze opdracht horen, worden gefactureerd onder naam van de aangegeven vestiging. Besteller verklaart hierbij akkoord te gaan met bovenstaande voorwaarde.'
								}</MakeText>
							</div>
						}

						{ publicDeliveryAfter5 &&
							<div className='row alignCenter mb-1 mt-3'>
								<MakeText style={{fontSize: 15}} fontFamily='Open Sans'>
									Bezorgadres is geen privé adres. Controleer of de bezorglocatie open is tot {moment(publicDeliveryAfter5.flower.delivery.windowEnd).format('HH:mm')}.
								</MakeText>
							</div>
						}

						<span className='smallText mt-2'>{this.state.errorMessage}</span>

						<span className='smallText mt-2'>{this.state.countryWarning}</span>
                    </div>
					{console.log('routingName', routingName, 'process.env.REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV)}
                    <Button testID='naarBetaling' style={{marginTop: 65, marginBottom: mobileView ? 25 : 0}} disabled={this.state.submitting} textStyle={{fontSize: 18, width: '100%', height: 50, fontFamily: 'Open Sans'}} text={routingName !== "consumersite" ? 'Bestelling plaatsen' : 'Door naar betaling'} color={'greenWhite'} onPress={() => this.nextPage()} />
                </div>
            </div>
        );
    }
}