import * as React from 'react';
import {getMe} from "../../API/Http/Requests";

// import { DataService } from './../../../API/DataService';
import { ILocation } from '../../API/IDataService';

import Button from '../CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import Address from './Address/Address';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

export const amsterdamFactuurAdres: ILocation = {
	company: 'Gemeente Amsterdam',
	street: 'Postbus',
	housenumber: '2602',
	postalcode: '1000 CP',
	fname: 'Gemeente',
	lname: 'Amsterdam',
	city: 'Amsterdam'
};

export default class PickDeliveryAddress extends React.Component<any> {

	state = {
		profile: undefined as any,
		billing: false,
		loading: true,
		deliveryNote: '',
		error: false,
		errorText: "",

		terAttentieVan: '',
		bestemdVoor: '',
		besteldDoor: '',

		deliveryAddress: undefined as any,
		billingAddress: undefined as any,
	}

	nextPage() {

		// If no address has been set, show an error message to the user.
		if(!this.state.deliveryAddress) {
			return this.setState({errorText: "Er is geen bezorgadres geselecteerd."})
		}

		// If there is no error, remove error text.
		this.setState({errorText: ""});

		const delAddress = this.state.deliveryAddress;
		delAddress.tav = `${this.state.bestemdVoor}|${this.state.besteldDoor}`;

		this.props.store.setDeliveryAddress(this.state.deliveryAddress);
		this.props.store.setDeliveryNote(this.state.deliveryNote);

		if(this.state.billing && this.state.billingAddress) this.props.store.setBillingAddress(this.state.billingAddress);
		else this.props.store.setBillingAddress(routingName === 'amsterdam' ? amsterdamFactuurAdres : this.state.deliveryAddress);

		this.props.navigation.navigate('payment');
	}

	componentDidMount() {
		getMe().then( profile => {
			if(!profile.locations.length || profile.locations.filter(loc => loc.active).length === 0) {
				this.props.navigation.navigate('deliveryAddress', {return: 'overview'});
			}
			// Reverse array so newest addresses are displayed first.
			profile.locations = profile.locations.reverse()
			this.props.store.setProfile(profile);
			const deliveryAddress = profile.locations.find(l => !l.invoice);
			let bestemdVoor = '';
			let besteldDoor = '';
			let tav = '';
			if (this.props.store.orderProcess.deliveryAddress && this.props.store.orderProcess.deliveryAddress.tav) {
				tav = this.props.store.orderProcess.deliveryAddress.tav;
				bestemdVoor = tav.split('|')[0];
				besteldDoor = tav.split('|')[1];
			}
			this.setState({
				profile,
				deliveryAddress,
				loading: false,
				deliveryNote: this.props.store.orderProcess.deliveryNote,
				bestemdVoor,
				besteldDoor,
			});
		}).catch( (e : Error) => {
			console.log(e);
			this.setState({error: true, loading: false});
		})
		this.props.store.setActiveStep('pickDeliveryAddress');
		GoogleAnalytics.getInstance().hit(this.props.navigation.state);
	}

	createLocations(type: string) {
		let locations = this.state.profile.locations.map((location, i) => {
			if(!location.active) {
				return;
			}
			//Returns if location is inactive, or if the invoice field is wrong if it exists.
			if((location.invoice !== undefined && ((!location.invoice && type === 'billing') || (location.invoice && type === 'delivery')))) {
				return;
			}
			let selected = false;

			if(this.state.deliveryAddress && type === "delivery") {
				if(location.id === this.state.deliveryAddress.id) {
					selected = true;
				}
			} else if( this.state.billingAddress && type === "billing") {
				if(location.id === this.state.billingAddress.id) {
					selected = true;
				}
			}

			return (
                <Address key={i} style={{marginBottom: 15}} location={location} type={type} setSelected={this.setSelected.bind(this)} selected={selected} />
			);
		});
		return locations;
	}

	setSelected(location: ILocation, type: string) {
		if(type === "delivery") return this.setState({deliveryAddress: location});
		if(type === "billing") return this.setState({billingAddress: location});
	}

	render() {
		// Go back to overview page if the totalprice is 0. This scenario happens when a user refreshes the page on this screen.
		if(this.props.store.orderProcess.totalPrice === 0) {
			this.props.navigation.navigate('overview');
		}

		const mobileView = this.props.mobileView;

		if(this.state.loading) {
			return (
				<div style={{marginTop: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
					<MakeText style={{fontSize: mobileView ? 24 : 36}} fontFamily={'Arno Pro'}>Adressen ophalen...</MakeText>
				</div>
			);
		}

		if(this.state.error) {
			return (
				<div style={{marginTop: 50}}>
					<MakeText style={{textAlign: 'center'}}>Er lijkt iets fout te gaan, probeer het later opnieuw.</MakeText>
					<Button style={{flex: 1, margin: 20, display: 'flex', justifyContent: 'center'}}color='greenWhite' text='terug' onPress={() => this.props.navigation.navigate('overview')} />
				</div>
			);
		}

		// const mobileCheckboxStyle = {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'} as React.CSSProperties;
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', height: '95%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-around', width: '100%', }}>
                    {/* Left block */}
                    <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : 475, height: mobileView ? undefined : 350, marginTop: mobileView ? 25 : undefined}}>
                        <MakeText style={{fontSize: mobileView ? 24 : 36, alignSelf: 'center'}} fontFamily={'Arno Pro'}>Bezorgadres</MakeText>
                        <a href='#' onClick={() => this.props.navigation.navigate('addDeliveryAddress')} style={{fontSize: 15, alignSelf: 'center', color: 'black', fontFamily: 'Open Sans', textDecoration: 'underline'}}>Ander bezorgadres</a><span style={{fontSize: 15, alignSelf: 'center', color: 'black', fontFamily: 'Open Sans'}}>* of kies een bestaand bezorgadres</span><span style={{fontSize: 15, alignSelf: 'center', color: 'black', fontFamily: 'Open Sans'}}>en m.v.t. verander de ontvangernaam met inputveld 'Bestemd voor'</span>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: mobileView ? undefined : 350, marginTop: 15, overflow: 'auto'}}>
                            {this.createLocations("delivery")}
                        </div>
                    </div>
                    {/* Right block */}
                    {/* this.state.billing && routingName === 'consumersite' &&
                    <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : 475, height: mobileView ? undefined : 350}}>
                        <MakeText style={{fontSize: mobileView ? 24 : 36, alignSelf: 'center'}} fontFamily={'Arno Pro'}>Factuuradres</MakeText>
                        <a href='#' onClick={() => this.props.navigation.navigate('addBillingAddress')} style={{fontSize: 15, alignSelf: 'center', color: 'black', fontFamily: 'Open Sans', textDecoration: 'underline'}}>Ander factuuradres</a>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: mobileView ? undefined : 350, marginTop: 15, overflow: 'auto'}}>
                            {this.createLocations("billing")}
                        </div>
                    </div>
                    */}
                </div>
                <div>
					{/* Checkbox */}
					{/* routingName === 'consumersite' &&
						<div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 10, marginLeft: 10, width: mobileView ? '80%' : undefined}}>
							<input type='checkbox' checked={this.state.billing} onChange={(e) => this.setState({billing: e.target.checked})}></input>
							<MakeText style={{fontSize: mobileView ? 15 : 18, marginLeft: 10}}>Ik wil een ander factuuradres gebruiken</MakeText>
						</div>
					*/}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {/* Error text */}
                        {this.state.errorText && <MakeText fontFamily={'Open Sans'} style={{fontSize: mobileView ? 15 : 18, color: 'red', marginTop: 10, alignSelf: 'flex-end'}}>{this.state.errorText}</MakeText>}
                    </div>
					<div className='row' style={{alignItems: 'center', justifyContent: 'center', marginTop: 10, width: '100%'}}>
						<div className='col' style={{width: '45%', alignItems: 'center'}}>
							<MakeText style={{fontSize: 16, marginTop: 15, marginBottom: 5}}>Bestemd voor (ontvangernaam)</MakeText>
							<input type='text' value={this.state.bestemdVoor} style={{fontSize: 15, padding: 5, width: mobileView ? '90%' : 400}} onChange={(e) => this.setState({bestemdVoor: e.currentTarget.value})} />
						</div>
						<div className='col' style={{width: '45%', alignItems: 'center'}}>
							<MakeText style={{fontSize: 16, marginTop: 15, marginBottom: 5}}>Besteld door</MakeText>
							<input type='text' value={this.state.besteldDoor} style={{fontSize: 15, padding: 5, width: mobileView ? '90%' : 400}} onChange={(e) => this.setState({besteldDoor: e.currentTarget.value})} />
						</div>
					</div>

					<div className='col' style={{width: '100%', alignItems: 'center'}}>
						<MakeText style={{fontSize: 16, marginTop: 15, marginBottom: 5}}>Opmerking voor de bezorger</MakeText>
						<input type='text' maxLength={50} value={this.state.deliveryNote} style={{fontSize: 15, padding: 5, width: mobileView ? '90%' : 400}} onChange={(e) => this.setState({deliveryNote: e.currentTarget.value})} />
					</div>
                </div>
				<div style={{width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginBottom: mobileView ? 25 : 0, marginTop: mobileView ? 50 : 0}}>
					<Button onPress={() => this.props.navigation.navigate('pickLogin')} color={'greenWhite'} text={'Terug'} textStyle={{fontSize: mobileView ? 15 : 18, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginLeft: mobileView ? 25 : 0}} />
					<Button testID='nextPage' style={{marginRight: mobileView ? 25 : 0}} textStyle={{fontSize: mobileView ? 15 : 18, fontFamily: 'Open Sans', height: mobileView ? undefined : 50}} text={'Naar afrekenen'} onPress={() => this.nextPage()} color={'greenWhite'} />
				</div>
            </div>
		);
	}
}
