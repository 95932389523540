import * as React from 'react';
import { getPackageById } from '../../API/Http/Requests';
import MakeText from '../Common/MakeText/MakeText';
import { Bundle, Package } from '../../API/Interfaces/Bundle';
import ProductImages from './ProductImages';
import Button from '../CrossPlatform/Button/Button';
import ProductDetailsModal from './ProductDetailsModal';
import { phone } from '../../Util/screen/HandleLayout';
import Collapsible from './Collapsible';
import './ProductDetails.css';
import { EURO } from '../../Util/currency';
// import configDefault from '../../config/config.selector';
import { sortPackages } from '../../Util/sizeOrdering';
import { routingName } from '../../Components/CrossPlatform/OrderProcess/NewOrderProcess.web';

// const conf = configDefault as any;

// Possible keys: title, desc, details
const findLocaleText = (flower: Package, key: string) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === key);
    if (locale && locale.value) {
        return locale.value;
    }
    if (key === 'title') {
        return flower.category;
    }
    return undefined;
}

const capitalize = (s: string) => {
    return s[0].toUpperCase() + s.slice(1);
}

export default class ProductDetails extends React.Component<any> {
    state: {bundleId: string, packageId: string, bundle: Bundle|undefined, package: Package|undefined, amount: number, showModal: boolean, clickedSub: boolean} = {
        bundleId: '',
        packageId: '',
        bundle: undefined,
        package: undefined,
        amount: 1,
        showModal: false,
        clickedSub: false,
    };

    async componentDidMount() {
        let bundleId = this.props.navigation.getParam('bId');
        let packageId = this.props.navigation.getParam('pId');
        if (!bundleId && !packageId) {
            return;
        }
        let bundle;
        let portal = routingName;
        const packaTemp = portal && portal !== '' ? await getPackageById(packageId, portal) : await getPackageById(packageId);
        bundle = packaTemp.product;
        if (bundle && bundle.length > 0) {
            sortPackages(bundle);
            bundleId = bundle.id;
        }
        let packa = bundle.packages[0];
        bundle.packages.map(pack => {
            if (pack.id === packageId) {
                packa = pack;
            }
        });
        
        const found = bundle.packages.find(p => p.id === Number(packageId));
        if (packageId && found) {
            packa = found;
        }
        this.setState({bundleId, packageId, package: packa, bundle});
    }

    updateSelectedPackage(size: string) {
        const bundle = this.state.bundle as Bundle;
        const pack = bundle.packages.find(p => p.size === size) as Package;
        window.history.pushState(undefined, "Product details", `/product/${pack.id}/${bundle.category}/${pack.size}`);
        this.setState({package: pack, packageId: pack.id});
    }

    addFlower(clickedSub: boolean) : void {
        this.setState({showModal: true, clickedSub});
    }

    render() {
        if (!this.state.bundle || !this.state.package) {
            return (
                <div>
                </div>
            );
        }
        const bundle = this.state.bundle as Bundle;
        const pack = this.state.package as Package;
        const mobileView = phone(this.props.width);
        const largeText = mobileView ? 24 : 36;
        const mediumText = mobileView ? 18 : 24;
        const smallText = mobileView ? 15 : 18;
        const style = {
            title: {
                marginTop: mobileView ? 15 : 0,
                marginBottom: 15,
                fontSize: largeText,
            } as React.CSSProperties,
            price: {
                fontSize: mediumText,
            },
            priceSub: {
                fontSize: smallText - 2,
            },
            deliveryContainer: {
                marginTop: mobileView ? 40 : 50,
            },
            deliveryText: {
                display: 'flex',
                flexDirection: 'row',
            } as React.CSSProperties,
            deliveryChecks: {
                display: 'flex',
                flexDirection: 'column',
            } as React.CSSProperties,
            deliveryCheck: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 10,
            } as React.CSSProperties,
            mediumText: {
                fontSize: smallText,
            },
            buttonText: {
                fontSize: smallText,
                width: '100%',
                height: mobileView ? undefined : 40,
                fontFamily: 'Open Sans',
                fontWeight: 'bold',
            } as React.CSSProperties,
            backToShopButton: {
                fontSize: smallText,
                height: mobileView ? undefined : 40,
                fontFamily: 'Open Sans',
            } as React.CSSProperties,
            descriptionContainer: {},
            descriptionTitle: {
                fontSize: mediumText,
                fontFamily: 'Arno Pro',
            } as React.CSSProperties,
            descriptionText: {
                fontSize: smallText,
                marginTop: 5,
                width: mobileView ? '90%' : '70%',
            } as React.CSSProperties,
        }
        const description = findLocaleText(this.state.package, 'desc');
        const details = findLocaleText(this.state.package, 'details');

        if (!bundle.published && bundle.category !== 'Overig') {
            return <div className = 'col mt-4' style={{width: '50%', alignSelf: 'center'}}>
                <span className='mediumText mb-4' style={{alignSelf: 'center'}}>Dit product is niet meer beschikbaar.</span>
                <Button testID='membershipButton' style={{alignSelf: 'center'}} textStyle={style.buttonText} text={'Terug naar de webshop'} color={'blackOpacity'} onPress={() => this.props.navigation.navigate('webshop')} />
            </div>
        }

        const deliveryCostsNote = () => (
            pack.packageIntname && pack.packageIntname.toLowerCase().indexOf('tulpen') > -1 ?
            <MakeText style={style.priceSub} fontFamily='Open Sans'>
                *Exclusief {EURO(4.99)} bezorgkosten.
            </MakeText> :
            <MakeText style={style.priceSub} fontFamily='Open Sans'>
                *Exclusief {EURO(8.10)} bezorgkosten.
            </MakeText>
        );

        return (
            <div className='container'>
                <div className='leftBlock'>
                    {/* Image component */}
                    <ProductImages images={[pack.image]} mobileView={mobileView} category={pack.category} />
                </div>
                <div className='rightBlock col'>
                    {/* De rest */}
                    <MakeText style={style.title} fontFamily='Arno Pro'>
                        {findLocaleText(this.state.package, 'title')}
                    </MakeText>
                    <MakeText style={style.price} fontFamily='Open Sans'>
                        {EURO(pack.price)}
                    </MakeText>
                    {(routingName !== 'amsterdam') ?
                    deliveryCostsNote()
                    :
                    <MakeText style={style.priceSub} fontFamily='Open Sans'>
                        *Inclusief bezorging.
                    </MakeText>
                    }

                    <div className='row optionsContainer'>
                        {/* Formaat en aantal dropdown */}
                        {((this.state.bundle.isFlowers || this.state.package.category.substring(0, 7) === 'E-Flora') && this.state.package.category.substring(0, 13) !== 'E-Flora-Plant') &&
                            <div className='col' style={{marginRight: mobileView ? 15 : undefined}}>
                                <MakeText style={style.mediumText} fontFamily='Open Sans'>
                                    Formaat
                                </MakeText>
                                <select name="size" id="size" value={this.state.package.size} onChange={(e) => this.updateSelectedPackage(e.target.value)} className='smallText selectStyle'>
                                    {bundle.packages.map(p => {
                                        if ((findLocaleText(p, 'title') !== 'Tederheid') && (findLocaleText(p, 'title') !== 'Feest') ||
                                            (findLocaleText(p, 'title') === 'Tederheid' && p.size !== 'small') ||
                                            (findLocaleText(p, 'title') === 'Feest' && (p.size !== 'small' && p.size !== 'large'))
                                        ) {
                                            return <option key={'dropdown_' + p.size} value={p.size}>{capitalize(p.size)}</option>
                                        }
                                    })}
                                </select>
                            </div>
                        }
                        <div className='col'>
                            <MakeText style={style.mediumText} fontFamily='Open Sans'>
                                Aantal
                            </MakeText>
                            <select name="amount" id="amount" value={this.state.amount} onChange={(e) => this.setState({amount: parseInt(e.target.value)})} className='smallText selectStyle'>
                                {(this.state.package.category === 'Overig' ? [1] : Array.from(Array(process.env.REACT_APP_NODE_ENV === "portal"  ? 50 : 20).keys())).map(a => <option key={'amount' + a} value={a + 1}>{a + 1}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className='row optionsContainer'>
                        {/* Bestel en abonnement knoppen */}
                        <Button testID='orderButton' style={{alignSelf: 'center', marginRight: 20}} textStyle={style.buttonText} text={'Bestel direct'} color={'blackOpacity'} onPress={() => this.addFlower(false)} />

                    </div>
                    <div style={{marginTop: 50}} />
                    {description &&
                        <div className='col borderTopLight' style={{paddingTop: 20, paddingBottom: 20}}>
                            {/* Beschrijving */}
                            <MakeText style={style.descriptionTitle} fontFamily='Arno Pro'>
                                Beschrijving
                            </MakeText>
                            <MakeText style={style.descriptionText} fontFamily='Open Sans'>
                                {findLocaleText(this.state.package, 'desc')}
                            </MakeText>
                        </div>
                    }
                    {details &&
                        <div className='col borderTopLight' style={{paddingTop: 20, paddingBottom: 20}}>
                            {/* Details */}
                            <Collapsible title='Details' titleStyle={style.descriptionTitle} containerStyle={style.descriptionContainer}>
                                <MakeText style={style.descriptionText} fontFamily='Open Sans'>
                                    {(findLocaleText(this.state.package, 'details') as string).split(', ').map(t => [`- ${t}`, <br />])}
                                </MakeText>
                            </Collapsible>
                        </div>
                    }
                    <div className='col borderTopLight borderBottomLight' style={{paddingTop: 20, paddingBottom: 20}}>
                        {/* Bezorging */}
                        <Collapsible title='Bezorging' titleStyle={style.descriptionTitle} containerStyle={style.descriptionContainer}>
                            { routingName === "amsterdam" &&
                                <MakeText style={style.descriptionText} fontFamily='Open Sans'>
                                    De bloemen van The Flower Family worden met een glimlach en zoveel mogelijk duurzaam bezorgd door LCA logistics. <br /><br />
                                    We bezorgen vijf dagen in de week - van maandag tot en met zaterdag van 09.00 - 21.00 uur.
                                </MakeText>
                            }
                            { routingName !== "amsterdam" && 
                                (bundle.isFlowers || pack.category.substring(0, 7) === 'E-Flora') &&
                                    (pack.category.substring(0, 7) === 'E-Flora' && 
                                        ((pack.category === 'E-Flora-Plant' && pack.packageIntname && pack.packageIntname.toLowerCase().indexOf('tulpen') > -1) ||
                                        pack.category !== 'E-Flora-Plant')) ?
                                            <MakeText style={style.descriptionText} fontFamily='Open Sans'>
                                                De bloemen van The Flower Family worden met een glimlach en zoveel mogelijk duurzaam bezorgd door LCA logistics. <br /><br />
                                                We bezorgen vijf dagen in de week - van maandag tot en met zaterdag van 09.00 - 21.00 uur. <br /><br />
                                                Het is mogelijk om je bestelling live te volgen. Je ontvangt één dag voorafgaand aan de bezorging een Track & Trace e-mail met daarin de verwachte bezorgtijd.
                                                <br />Voor zaterdagen worden onze boeketten bezorgd in een mooie cadeaudoos, via onze partner E-Flora.
                                            </MakeText>
                                            :
                                            <MakeText style={style.descriptionText} fontFamily='Open Sans'>
                                                De planten van The Flower Family worden bezorgd in een mooie cadeaudoos, via onze partner E-Flora.<br /><br />
                                                Houd er rekening mee dat de bezorging 3-5 dagen kan duren!
                                                {/* De planten van The Flower Family worden bezorgd in een mooie cadeaudoos door PostNL.<br /><br />
                                                PostNL bezorgt landelijk op:<br />
                                                    Di:  09.00 - 22.00 uur<br />
                                                    Do: 09.00 - 22.00 uur<br />
                                                    Za: 09.00 - 22.00 uur<br /><br />
                                                    Bestel vóór zondag 22.00 en ontvang de bloemen dinsdag. Of bestel vóór dinsdag 22.00 uur en ontvang de bloemen op donderdag of vrijdag.<br /><br />
                                                    Het is mogelijk om je bestelling live te volgen. Je ontvangt één dag voorafgaand aan de bezorging een Track & Trace e-mail met daarin de verwachte bezorgtijd, zodat jij alvast een mooie plek voor de plant kan vrijmaken.<br /><br />
                                        Houd er rekening mee dat PostNL het extra druk heeft momenteel - hierdoor kunnen bezorgingen vertraging oplopen. */}
                                            </MakeText>
                            }
                        </Collapsible>
                    </div>
                    <div className='col' style={{marginTop: 30}}>
                        <Button style={{}} textStyle={style.backToShopButton} text={'Terug naar webshop'} color={'blackOpacity'} onPress={() => this.props.navigation.navigate('webshop')} />
                    </div>
                </div>
                {/* Modal that opens when order button is clicked */}
                <ProductDetailsModal
                    active={this.state.showModal}
                    sub={this.state.clickedSub}
                    bundle={bundle}
                    package={pack}
                    amount={this.state.amount}
                    navigation={this.props.navigation}
                    subscription={this.state.clickedSub}
                    setActive={(showModal: boolean) => this.setState({ showModal })}
                    mobileView={mobileView}
                    width={this.props.width}
                />
            </div>
        )
    }
}

{/* <div style={style.deliveryContainer}>
    <div style={style.deliveryText}>
        <img src={'/images/delivery.svg'} style={{marginRight: 20, width: 30}} />
        <MakeText style={{ fontWeight: 'bold', ...style.mediumText }} fontFamily='Open Sans'>
            {this.state.bundle.isFlowers && 'Bezorging € 4,95 <br />'}
            zondag voor 22:00 besteld is dinsdag of woensdag in huis!
        </MakeText>
    </div>
    {!mobileView &&
        <div style={style.deliveryChecks}>
            <div style={style.deliveryCheck}>
                <img src={'/images/check.svg'} style={{marginRight: 20, marginLeft: 10, width: 20}} />
                <MakeText style={style.mediumText} fontFamily='Open Sans'>
                    Voorbeeldtekst Gecertificeerde kwekers met een MPS-A certificering
                </MakeText>
            </div>
            <div style={style.deliveryCheck}>
                <img src={'/images/check.svg'} style={{marginRight: 20, marginLeft: 10, width: 20}} />
                <MakeText style={style.mediumText} fontFamily='Open Sans'>
                    Voorbeeldtekst Gecertificeerde kwekers met een MPS-A certificering
                </MakeText>
            </div>
            <div style={style.deliveryCheck}>
                <img src={'/images/check.svg'} style={{marginRight: 20, marginLeft: 10, width: 20}} />
                <MakeText style={style.mediumText} fontFamily='Open Sans'>
                    Voorbeeldtekst Gecertificeerde kwekers met een MPS-A certificering
                </MakeText>
            </div>
        </div>
    }
</div> */}
