import {Dimensions} from "react-native";
import {SHOPPING_CART_HEIGHT, SHOPPING_CART_HEIGHT_4K} from "../../Util/screen/HandleLayout";

interface IState {
	productsIds: number[],
	products: any[],
	// The max height of the orderProcess drawer.
	maxHeight: number,
}

const INITIAL_STATE: IState = {
	productsIds: [],
	products: [],
	// The max height of the orderProcess drawer.
	maxHeight: 0,
};

const ProductReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case 'ADD_PRODUCT':
			let { products, productsIds, maxHeight } = state;

			// If product already exists in state.
            for(const product of products) {
                if(product.flower.id === action.payload.flower.id) {
                    return state;
                }
			}
			const amounty = action.payload.amount || 1;

			const newProduct = Object.assign({}, action.payload);
			newProduct.flower.amount = amounty;

			products.push(newProduct);

			const newState = { products, productsIds, maxHeight };
			return newState;

		case 'REMOVE_PRODUCT':
			let removeState = {...state};
			removeState.products.map( (product, index) => {
				if(product.flower.id === action.payload) {
					removeState.products.splice(index, 1);
				}
			})

			return removeState;

		case 'SET_PRODUCTS':

			let newState3 = {...state};
			newState3.products = []
			newState3.products = action.payload;

			return newState3;

		case 'UPDATE_PRODUCT':
			let state2 = Object.assign({}, state);

			state2.products.map( (product, index) => {
				if(product.flower.id === action.payload.id) {
					state2.products[index].flower = action.payload;
				}
			});

			return state2;

		case 'SET_PRODUCT_AMOUNT':
			let tempState = Object.assign({}, state);

			const id = action.payload.id;
			const amount = action.payload.amount;

			let product = tempState.products.filter( product => product.id === id);

			product["amount"] = amount;

			return tempState;

		case 'TOGGLE_DRAWER':
			let heightState = Object.assign({}, state);
			if(heightState.maxHeight === 0) {
				if(Dimensions.get('screen').height > 1100) {
					heightState.maxHeight = SHOPPING_CART_HEIGHT_4K
				} else {
					heightState.maxHeight = SHOPPING_CART_HEIGHT
				}
			} else {
				heightState.maxHeight = 0
			}

			// heightState.maxHeight === 0 ? heightState.maxHeight = SHOPPING_CART_HEIGHT: heightState.maxHeight = 0;

			return heightState;

    	default:
      		return state
  	}
};

export default ProductReducer;
