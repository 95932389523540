import { ILocation } from "../../API/IDataService";
import axios from 'axios';

export default async (location: ILocation) => {
    // feature depends on the API from https://www.api-postcode.nl/
    const axiosInstance = axios.create({ baseURL: '', timeout: 20000, responseType: 'json' });
    // create an array for filtered addresses result (in the end we will return only the first element in this array)
    let addressLookedUp:[{ straatnaam:string|undefined, woonplaatsnaam:string|undefined, housenumbers:string[], additions:string[] }] = [{
        straatnaam: undefined,
        woonplaatsnaam: undefined,
        housenumbers: [''],
        additions: [''],
    }];
    if (location.postalcode) {
        // get addresses from server (axios request)
        const addresses = await axiosInstance.get(`https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?rows=100&fl=straatnaam huisnummer huisletter huisnummertoevoeging huis_nlt postcode woonplaatsnaam&q=postcode%3A` +
            `${location.postalcode.replace(/\s/g,'').toUpperCase()}`)
            .then(res => {
                if (res.data.response) {
                    return res.data.response.docs.filter(doc => doc.huisnummer);
                }
            })
            .catch(() => undefined)
        // temporarily store 'housenumbers' and 'additions' outside the 'addressLookup' address-array variable
        let housenumbers:string[] = [];
        let additions:string[] = [];
        // filter Dutch addresses by postalcode input
        if (addresses && addresses.length > 0) {			
            addressLookedUp = addresses.filter(addressData => {
                if (addressData.huisnummer) {
                    if (addressData.huisnummer && addressData.huisnummer.toString().trim().length > 0) {
                        if (housenumbers.indexOf(addressData.huisnummer.toString().trim()) === -1) {
                            housenumbers = housenumbers[0] === '' ? [] : housenumbers;
                            housenumbers.push(addressData.huisnummer.toString().trim());
                        }
                        if (addressData.huisnummer.toString().trim() === location.housenumber.toString().trim()) {
                            additions = additions[0] === '' ? [] : additions;
                            if (addressData.huisnummertoevoeging) {
                                if (additions.indexOf(addressData.huisnummertoevoeging.toString().trim()) === -1) {
                                    addressData.huisnummertoevoeging.trim().toLowerCase() === 'o' ?
                                    additions.push('0') :
                                    additions.push(addressData.huisnummertoevoeging.toString().trim());
                                }
                            } else if (addressData.huisletter) {
                                if (additions.indexOf(addressData.huisletter.trim()) === -1) {
                                    addressData.huisletter.trim().toLowerCase() === 'o' ?
                                    additions.push('0') :
                                    additions.push(addressData.huisletter.trim());
                                }
                            }
                        }
                        addressData.housenumbers = [];
                        addressData.additions = [];
                        return true;
                    }
                    return false;
                }
            });
        }
        // add to the first address (with index 0) in 'addressLookup' an array 'housenumbers' for all housenumbers in filtered addresses
        if (housenumbers && housenumbers[0]) {
            // sort housenumbers (first numeric then non-numeric)
            housenumbers.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
            // housenumbers array
            housenumbers.map(housenumber => {
                if (addressLookedUp[0].housenumbers) {
                    addressLookedUp[0].housenumbers.push(housenumber);
                }
            });
        }
        // add to the first address (with index 0) in 'addressLookup' an array 'additions' for all additions in filtered addresses
        if (additions && additions[0]) {
            // sort additions (first numeric then non-numeric)
            additions.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
            // split numeric and non-numeric elements
            let additionsNonNumeric: string[] = [];
            additions.map(addition => {
                if (addressLookedUp[0].additions) {
                    if (typeof Number(addition) === 'number' && isFinite(Number(addition))) {
                        addressLookedUp[0].additions.push(addition);
                    } else {
                        additionsNonNumeric.push(addition);
                    }
                }
            });
            // arrange numeric and non-numeric elements based on common sense
            additionsNonNumeric.map(nonNumAdd => {
                if (nonNumAdd.toLowerCase() === 'h' || nonNumAdd.toLowerCase() === 'hs' || nonNumAdd.toLowerCase() === 'huis') {
                    if (Number(addressLookedUp[0].additions[0]) === 0) { // when 1st element is level 0
                        addressLookedUp[0].additions.splice(1, 0, nonNumAdd);
                    } else if (Number(addressLookedUp[0].additions[0]) === 1) { // when 1st element is level 1
                        addressLookedUp[0].additions.splice(addressLookedUp[0].additions.indexOf('0'), 0, nonNumAdd);
                    }  else if (addressLookedUp[0].additions.indexOf('0') < 0) { // when 1st element < 0 (below normal house entrancelevel)
                        addressLookedUp[0].additions.splice(addressLookedUp[0].additions.indexOf('0'), 0, nonNumAdd);
                    }
                }  else {
                    addressLookedUp[0].additions.push(nonNumAdd);
                }
            });
        }

        // Return
        return addressLookedUp[0] && addressLookedUp[0].housenumbers && addressLookedUp[0].housenumbers.length > 0 ?
        {
            street: addressLookedUp[0].straatnaam ? addressLookedUp[0].straatnaam  : '',
            city: addressLookedUp[0].woonplaatsnaam ? addressLookedUp[0].woonplaatsnaam : '',
            housenumbers: addressLookedUp[0].housenumbers,
            additions: addressLookedUp[0].additions ? addressLookedUp[0].additions : [''],
        } :
        addressLookedUp[0];
    }
}