import React from 'react';
import Modal from 'react-modal';
import {addProduct, toggleOrderDrawer} from '../../store/actions/ProductActions';
import Storage from '../CrossPlatform/Storage/Storage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import BubblesModal from './BubblesModal';
import ChocolonelyModal from './ChocolonelyModal';
import VaseModal from './VaseModal';
import KaartjeModal from './KaartjeModal';
import DeliveryModal from './DeliveryModal';
import { DeliveryOption, DeliveryProviders, SubscriptionRecurrency, deliveryOptionToString } from '../../API/Interfaces/DeliveryOption';
import moment from 'moment';
import { getDeliveryOptionsByPackageId } from '../../API/Http/Requests';
import { mobileOrderProcess } from '../../Util/screen/HandleLayout';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

interface IModalState {
    activeStep: number,
    vase: boolean | undefined,
    bubbles: boolean | undefined,
    chocolonely: boolean | undefined,
    noKaart: boolean | undefined,
    kaart1: boolean | undefined,
    kaart2: boolean | undefined,
    spoedBestelling: boolean,
    kaartText: string,
    vaseCode: string,
    wishcardCode: string,
    bubblesCode: string,
    chocolonelyCode: string,
    chosenDeliveryIndex: number,
    recurring: SubscriptionRecurrency | undefined,
    deliveryOptions: DeliveryOption[],
    deliveryPackage: string | undefined,
    modalHeight: number,
    spoedDelivery: DeliveryOption | undefined,
    activePage: number,
}

class ProductDetailsModal extends React.Component<any, IModalState> {

    isTulpenNL = this.props.package.packageIntname ? this.props.package.packageIntname && this.props.package.packageIntname.toLowerCase().indexOf('tulpen') > -1 : false;

    state = {
        activeStep: this.isTulpenNL ? 3 : 1,
        vase: undefined,
        bubbles: undefined,
        chocolonely: undefined,
        noKaart: undefined,
        kaart1: undefined,
        kaart2: undefined,
        kaartText: '',
        wishcardCode: '',
        bubblesCode: '',
        chocolonelyCode: '',
        vaseCode: '',
        chosenDeliveryIndex: 0,
        recurring: SubscriptionRecurrency.weekly,

        deliveryOptions: [],
        deliveryPackage: undefined,

        modalHeight: 600,
        spoedBestelling: false,
        spoedDelivery: undefined,

        activePage: 1,
    };

    storage = new Storage;

    async componentDidMount() {
        this.fetchOptions();
    }

    finish() {
        // Add order to the store, or pass options to product details which passes it to the store
        const flower = this.props.package;
        const bundle = this.props.bundle;
		// Check if the product already exists in the store.
		let exists = false;
		for(const product of this.props.product.products) {
			if(product.flower.id === flower.id) {
				exists = true;
			}
		}
        // If it does not, add it to the store and the localstorage.
        flower.vaas = this.state.vase;
        flower.note = this.state.kaart1 || this.state.kaart2 ? this.state.kaartText : '';
        flower.chocolonely = this.state.chocolonely;
        flower.bubbles = this.state.bubbles;
        flower.vaseCode = this.state.vaseCode;
        flower.wishcardCode = this.state.wishcardCode;
        flower.bubblesCode = this.state.bubblesCode;
        flower.chocolonelyCode = this.state.chocolonelyCode;
        flower.subscription = this.props.sub;
        flower.type = this.props.sub ? this.state.recurring : 'single';

        if (this.state.spoedBestelling) {
            flower.delivery = Object.assign({}, this.state.spoedDelivery);
        } else {
            flower.delivery = Object.assign({}, this.state.deliveryOptions[this.state.chosenDeliveryIndex]);
        }

		if(!exists) {
            this.storage.storeDataToList('products', {flower: Object.assign(flower, {amount: this.props.amount}), bundle});
            this.props.addProduct({flower, bundle, amount: this.props.amount});
        }
        this.setState({ chosenDeliveryIndex: 0 });
        if (mobileOrderProcess(this.props.width)) {
            this.props.navigation.navigate('overview');
        } else {
            this.props.toggleOrderDrawer();
        }
        this.closeModal();
    }

    closeModal() {
        this.setState({activeStep: this.isTulpenNL ? 3 : 1});
        this.props.setActive(false);
    }

    nextStep() {
        if (this.state.activeStep === 4) { // step 4 ('select delivery moment') is the last modalstep
            this.finish();
        } else {
            this.state.activeStep === 1 && !this.state.chocolonely ? // when no chocolonely selected skip the chocolonely modal step
            this.setState({activeStep: this.state.activeStep + 2}) : 
            this.setState({activeStep: this.state.activeStep + 1});
        }
    }

    previousStep() {
        if (this.isTulpenNL && this.state.activeStep === 3) { return; }
        this.state.activeStep === 3 && !this.state.chocolonely ? // when no chocolonely selected skip the chocolonely modal step
        this.setState({activeStep: this.state.activeStep - 2}) :
        this.setState({activeStep: this.state.activeStep - 1});
    }

    updateDelivery(chosenDeliveryIndex: number, recurring?: SubscriptionRecurrency) {
        const update = {chosenDeliveryIndex};
        if (recurring) { update['recurring'] = recurring; }
        this.setState(update);
    }

    setSpoed(s: boolean): void {
        if (s) {
            const spoedDelivery: DeliveryOption = {
                windowStart: new Date(),
                windowEnd: moment().add(4, 'hours').toDate(),
                deliveryProvider: DeliveryProviders.postnl,
                extraCost: 0,
                active: true,
                amountAvailable: 1000,
                sameDay: true,
            }
            this.setState({
                spoedBestelling: s,
                spoedDelivery,
            });
        } else {
            this.setState({
                spoedDelivery: undefined,
                spoedBestelling: s,
            })
        }
    }

    async fetchOptions() {
        // Filter out options that are too close to current time in case back-end lets some slip through
        const delOptions: DeliveryOption[] = (await getDeliveryOptionsByPackageId(this.props.package.id)).filter(d => moment().isBefore(moment(d.orderBefore)));
        let filteredOptions: DeliveryOption[] = delOptions.sort((d1: DeliveryOption, d2: DeliveryOption) => moment(d2.windowStart).isBefore(moment(d1.windowStart)) ? 1 : -1);
        // No deliveries on Sunday
        filteredOptions = filteredOptions.filter(o => moment(o.windowStart).day() > 0);
        // Only show monday and tuesday if amsterdam subscription
        if (routingName === "amsterdam" && this.props.sub) {
            filteredOptions = filteredOptions.filter(o => moment(o.windowStart).day() <= 2);
        }
        // TulpenNL bezorgopties
        else if (this.isTulpenNL) {
            filteredOptions = filteredOptions.filter(o => moment(o.windowStart).day() > 1 && deliveryOptionToString(o).toLowerCase().indexOf('eco') > -1)
        }
        // Saturday deliveries are only eco-all-day
        else {
            filteredOptions = filteredOptions.filter(o => {
                if (moment(o.windowStart).day() === 6) {
                    return deliveryOptionToString(o).toLowerCase().indexOf('eco') > -1;
                } else {
                    return true;
                }
            })
        }
        let chosenDeliveryIndex = 0;
        
        const newState = {modalHeight: window.innerHeight * 0.8, deliveryOptions: filteredOptions, deliveryPackage: this.props.package.id, chosenDeliveryIndex};
        this.setState(newState);
    }

    getActiveStep() {
        switch (this.state.activeStep) {
            case 1:
                return <VaseModal
                    package={this.props.package}
                    vase={this.state.vase}
                    vaseCode={this.state.vaseCode}
                    bubbles={this.state.bubbles}
                    chocolonely={this.state.chocolonely}
                    setVase={(vase: boolean) => this.setState({vase})}
                    staticVasePrice={this.props.app.config.variablePrices.vasePrice}
                    setVaseCode={(vaseCode: string) => this.setState({vaseCode: vaseCode})}
                    setBubbles={(bubbles: boolean) => this.setState({bubbles})}
                    setChocolonely={(chocolonely: boolean) => this.setState({chocolonely})}
                    nextPage={() => this.nextStep()}
                    mobileView={this.props.mobileView}
                />
            /* case 2:
                return <BubblesModal
                    bubbles={this.state.bubbles}
                    bubblesCode={this.state.bubblesCode}
                    setBubblesCode={(bubblesCode: string) => this.setState({bubblesCode: bubblesCode})}
                    activePage={this.state.activePage}
                    onChange={(page: number) => this.setState({activePage: page})}
                    prevPage={() => this.previousStep()}
                    nextPage={() => this.nextStep()}
                    mobileView={this.props.mobileView}
                /> */
            case 2:
                return <ChocolonelyModal
                    chocolonely={this.state.chocolonely}
                    chocolonelyCode={this.state.chocolonelyCode}
                    setChocolonelyCode={(chocolonelyCode: string) => this.setState({chocolonelyCode: chocolonelyCode})}
                    activePage={this.state.activePage}
                    onChange={(page: number) => this.setState({activePage: page})}
                    prevPage={() => this.previousStep()}
                    nextPage={() => this.nextStep()}
                    mobileView={this.props.mobileView}
                />
            case 3:
                return <KaartjeModal
                    package={this.props.package}
                    kaartPrijs={this.props.app.config.variablePrices.ticket}
                    noKaart={this.state.noKaart}
                    kaart1={this.state.kaart1}
                    kaart2={this.state.kaart2}
                    kaartText={this.state.kaartText}
                    wishcardCode={this.state.wishcardCode}
                    setNoKaart={() => this.setState({noKaart: true, kaart1: false, kaart2: false, wishcardCode: ''})}
                    setKaart1={(kaart1: boolean) => this.setState({kaart1, kaart2: false, noKaart: false, wishcardCode: 'GC001'})}
                    setKaart2={(kaart2: boolean) => this.setState({kaart1: false, kaart2, noKaart: false})}
                    setText={(kaartText: string) => this.setState({kaartText: kaartText})}
                    setKaartCode={(wishcardCode: string) => this.setState({wishcardCode: wishcardCode})}
                    prevPage={() => this.previousStep()}
                    nextPage={() => this.nextStep()}
                    mobileView={this.props.mobileView}
                    activePage={this.state.activePage}
                    onChange={(page: number) => this.setState({activePage: page})}
                />
            case 4:
                return <DeliveryModal
                    options={this.state.deliveryOptions}
                    subscription={this.props.sub}
                    chosenDeliveryIndex={this.state.chosenDeliveryIndex}
                    recurring={this.state.recurring}
                    setDelivery={(delivery: number, recurring?: SubscriptionRecurrency) => this.updateDelivery(delivery, recurring)}
                    spoed={this.state.spoedBestelling}
                    setSpoed={s => this.setSpoed(s)}
                    prevPage={() => this.previousStep()}
                    nextPage={() => this.nextStep()}
                    mobileView={this.props.mobileView}
                    package={this.props.package}
                />
            default:
                console.log('Invalid modal step');
        }
    }

    render() {
        const mobileView = this.props.mobileView;
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                height                : mobileView ? this.state.modalHeight : undefined,
                width                 : mobileView ? '80%' : undefined,
            }
        };

        if (this.state.deliveryPackage !== this.props.package.id) {
            this.fetchOptions();
        }

        return (
            <Modal
                isOpen={this.props.active}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                {this.getActiveStep()}
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { product, orderProcess, app } = state
    return { product, orderProcess, app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addProduct,
        toggleOrderDrawer,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsModal);
