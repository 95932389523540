import * as React from 'react';
import MakeText from '../../Common/MakeText/MakeText';

const colors = {
    accent: '#707070',
    fill: '#989898',
};

export default (props: {index: number, title: string, diameter: number, width: number, marginRight?: number, indexSize?: number, smallText?: boolean, clickable: boolean, active: boolean, onClick: () => void}) => {
    const marginRight = props.marginRight || 120;
    const dashLength = marginRight + props.width;
    const indexSize = props.indexSize || 24;
    const smallText = props.smallText || false;

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: props.width, cursor: props.clickable ? 'pointer' : 'auto', marginRight: props.index < 4 ? marginRight : 0}} onClick={() => props.onClick()}>
            <div style={{position: 'relative', borderRadius: '50%', borderColor: colors.accent, borderStyle: 'solid', borderWidth: 1, backgroundColor: props.active ? colors.fill : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: props.diameter, height: props.diameter}}>
                <MakeText style={{fontSize: indexSize, color: props.active ? 'white' : colors.accent, textAlign: 'center', width: props.diameter + 2}} fontFamily='Open Sans'>{props.index}</MakeText>
                {props.index < 4 && <div style={{borderBottomColor: colors.accent, borderBottomWidth: 1, borderBottomStyle: 'solid', height: 0, width: dashLength, position: 'absolute', top: '50%', right: -dashLength}} />}
            </div>
            <MakeText style={{fontSize: smallText ? 13 : 18, fontWeight: props.active ? 'bold' : 'normal', color: 'black'}} fontFamily='Open Sans'>{props.title.split(' ')[0]}</MakeText>
            {props.index == 1 && <MakeText style={{fontSize: smallText ? 13 : 18, fontWeight: props.active ? 'bold' : 'normal', color: 'grey', fontStyle: 'italic'}} fontFamily='Open Sans'>{props.title.split(' ')[1] + ' ' + props.title.split(' ')[2]}</MakeText>}
        </div>
    )
}