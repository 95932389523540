import * as React from 'react';
import NavbarBall from './NavbarBall';

const stepIndices = {
    'overview': 0,
    'pickLogin': 1,
    'pickDeliveryAddress': 2,
    'payment': 3,
};

export default class OrderProcessNavbar extends React.Component<{navigation: any, activeStep: string, panelOpen: boolean}> {
    onClickProp(id: string) {
        return this.isClickable(id) ?
            {onClick :() => this.props.navigation.navigate(id)}
        :
            {onClick: () => undefined}
    }

    isClickable(id: string) {
        return this.props.panelOpen && stepIndices[id] <= stepIndices[this.props.activeStep];
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'row', width: '60%', justifyContent: 'center', alignSelf: 'center'}}>
                <NavbarBall index={1} title='Bloemenmand (aanpassen mogelijk)' diameter={42} width={120} active={this.props.activeStep === 'overview'} clickable={this.isClickable('overview')} {...this.onClickProp('overview')} />

                <NavbarBall index={2} title='Inloggen' diameter={42} width={120} active={this.props.activeStep === 'pickLogin'} clickable={this.isClickable('pickLogin')} {...this.onClickProp('pickLogin')} />

                <NavbarBall index={3} title='Bezorgadres' diameter={42} width={120} active={this.props.activeStep === 'pickDeliveryAddress'} clickable={this.isClickable('pickDeliveryAddress')} {...this.onClickProp('pickDeliveryAddress')} />

                <NavbarBall index={4} title='Afrekenen' diameter={42} width={120} active={this.props.activeStep === 'payment'} clickable={this.isClickable('payment')} {...this.onClickProp('payment')} />
            </div>
        );
    }
}