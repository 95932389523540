import * as React from 'react';
import { EURO } from '../../Util/currency';
import { Package } from '../../API/Interfaces/Bundle';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import TouchableOpacity from '../CrossPlatform/TouchableOpacity/TouchableOpacity';
import './KaartjeModal.css';
import Pagination from '../CrossPlatform/Pagination/Pagination';

const checkIndexIsEven = n => n % 2 == 0;

const kaartjeModal = (props: {package: Package, kaartPrijs: number, noKaart: boolean | undefined, kaart1: boolean | undefined, kaart2: boolean | undefined, kaartText: string, activePage: number, mobileView: boolean, 
        setKaart1: (k: boolean) => void, setKaart2: (k: boolean) => void, setNoKaart: () => void, setText: (k: string) => void,
        setKaartCode: (c: string) => void, wishcardCode: string, onChange: (p: number) => void,  prevPage: (() => void) | undefined, nextPage: () => void}) => {
    const mobileView = props.mobileView;
    const maxChars = props.package.packageIntname && props.package.packageIntname.toLowerCase().indexOf('tulpen') === -1 ? 330 : 230; // The wishcard length for TulpenNL is 230 characters.
    const wishcardArr:any = [
        { id: 1, name: 'Dankjewel', price: 2.5, wishcardCode: '2dan', },
        { id: 2, name: 'Unicorn', price: 2.5, wishcardCode: '2uni', },
        { id: 3, name: 'Vriendin', price: 2.5, wishcardCode: '2vri', },
        { id: 4, name: 'Gefeliciteerd', price: 2.5, wishcardCode: 'GC104', },
        { id: 5, name: 'Congrats', price: 2.5, wishcardCode: 'GC105', },
        { id: 6, name: 'Hart Nude', price: 2.5, wishcardCode: 'GC117', },
        { id: 7, name: 'Hartje', price: 2.5, wishcardCode: 'GC120', },
        { id: 8, name: 'Stay safe', price: 2.5, wishcardCode: 'GC126', },
        { id: 9, name: 'Proud of you', price: 2.5, wishcardCode: 'GC128', },
        { id: 10, name: 'Happy anniversary', price: 2.5, wishcardCode: 'GC130', },
        { id: 11, name: `You're engaged`, price: 2.5, wishcardCode: 'GC131', },
        { id: 12, name: 'Gefeliciteerd', price: 2.5, wishcardCode: 'GC132', },
        { id: 13, name: 'Topper', price: 2.5, wishcardCode: 'GC133', },
        { id: 14, name: 'Bird', price: 2.5, wishcardCode: 'GC139', },
        { id: 15, name: 'Veel liefs', price: 2.5, wishcardCode: 'GC140', },
        { id: 16, name: 'Happy Day', price: 2.5, wishcardCode: 'GC145', },
        { id: 17, name: 'Giraffe', price: 2.5, wishcardCode: 'GC146', },
        { id: 18, name: 'Hello girl', price: 2.5, wishcardCode: 'GC151', },
        { id: 19, name: 'Heel veel beterschap', price: 2.5, wishcardCode: 'GC152', },
        { id: 20, name: 'Gecondoleerd', price: 2.5, wishcardCode: 'GC154', },
        { id: 21, name: 'Get well soon', price: 2.5, wishcardCode: 'GC155', },
        { id: 22, name: 'Goodbye sleep', price: 2.5, wishcardCode: 'GC164', },
        { id: 23, name: 'Succes je kan het', price: 2.5, wishcardCode: 'GC175', },
        { id: 24, name: 'Trots op jou', price: 2.5, wishcardCode: 'GC180', },
        { id: 25, name: 'Veel sterkte', price: 2.5, wishcardCode: 'GC182', },
        { id: 26, name: 'Veel sterkte roze', price: 2.5, wishcardCode: 'GC183', },
        { id: 27, name: 'Samen staan we sterk', price: 2.5, wishcardCode: 'GC188', },
        { id: 28, name: 'Geslaagd', price: 2.5, wishcardCode: 'GC190', },
        { id: 29, name: 'Sending love', price: 2.5, wishcardCode: 'GC217', },
        { id: 30, name: 'Plant', price: 2.5, wishcardCode: 'GC220', },
        { id: 31, name: 'Plantje', price: 2.5, wishcardCode: 'GC222', },
        { id: 32, name: 'Roses', price: 2.5, wishcardCode: 'GC223', },
        { id: 33, name: 'Zwanger', price: 2.5, wishcardCode: 'GC226', },
        { id: 34, name: 'You are the best', price: 2.5, wishcardCode: 'GC227', },
        { id: 35, name: 'Candles', price: 2.5, wishcardCode: 'GC235', },
        { id: 36, name: 'Beleaf', price: 2.5, wishcardCode: 'GC241', },
        { id: 37, name: 'Happy Birthday', price: 2.5, wishcardCode: 'GC247', },
        { id: 38, name: 'Grashalm', price: 2.5, wishcardCode: 'GC266', },
        { id: 39, name: 'Grow girl', price: 2.5, wishcardCode: 'GC242', },
        { id: 40, name: 'Plantastic', price: 2.5, wishcardCode: 'GC243', },
        { id: 41, name: 'Plant Dankjewel', price: 2.5, wishcardCode: 'GC244', },
        { id: 42, name: 'Plant Beterschap', price: 2.5, wishcardCode: 'GC245', },
        { id: 43, name: 'Plant Denk Aan Je', price: 2.5, wishcardCode: 'GC246', },
        { id: 44, name: 'Thank You Plants', price: 2.5, wishcardCode: 'GC248', },
        { id: 45, name: 'Green Leaves', price: 2.5, wishcardCode: 'GC250', },
        { id: 46, name: 'Green Leaf', price: 2.5, wishcardCode: 'GC251', },
        { id: 47, name: 'Yay', price: 2.5, wishcardCode: 'GC254', },
        { id: 48, name: 'Words Puzzle', price: 2.5, wishcardCode: 'GC258', },
        { id: 49, name: 'Snow', price: 2.5, wishcardCode: 'GC274', },
        { id: 50, name: 'Lovey Dovey', price: 2.5, wishcardCode: 'GC323', },
        { id: 51, name: 'Fijne Kerst', price: 2.5, wishcardCode: 'GC269', },
        { id: 51, name: 'Happy New Year', price: 2.5, wishcardCode: 'GC363', },
        { id: 51, name: `Merry Christmas & 
        Happy New Year`, price: 2.5, wishcardCode: 'GC283', },
    ];
    wishcardArr.sort((a:any, b:any) => a.name.charCodeAt(0) - b.name.charCodeAt(0));
    const itemsCountPerPage = 4;
    const startIndexWishcards = (itemsCountPerPage * (props.activePage - 1));

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', overflow: 'hidden', marginTop: props.kaart2 ? '-60px' : '0'}}>
            <MakeText style={{ fontSize: mobileView ? 24 : 36 }} fontFamily='Arno Pro'>Wenskaartje toevoegen?</MakeText>
        <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%',height: '100%', position: 'relative'}}>
            <TouchableOpacity testID='noKaartje' onPress={() => {props.setNoKaart()}} style={{display: 'flex', flexDirection: 'row', marginTop: mobileView ? 25 : 25, alignItems: 'center', maxWidth: '100%'}}>
                <input type="checkbox" id="kaart" style={{marginLeft: 20, marginRight: 10}} name="kaart" defaultChecked={props.noKaart === true} />
                <MakeText style={{ fontSize: 16, width: 100 }} fontFamily='Open Sans'>Geen kaartje</MakeText>
            </TouchableOpacity>
            { !props.kaart1 && 
                <TouchableOpacity testID='yesKaartje' onPress={() => props.setKaart1(true)} style={{ display: 'flex', flexDirection: 'row', marginTop: mobileView ? 25 : 25, alignItems: 'center', maxWidth: '100%' }}>
                    <input type="checkbox" id="kaart1" style={{ marginLeft: 20, marginRight: 10 }} name="kaart" defaultChecked={props.kaart1} />
                    <MakeText style={{ fontSize: 16, width: 260 }} fontFamily='Open Sans'>Voeg standaardkaartje toe + {EURO(props.kaartPrijs)}</MakeText>
                </TouchableOpacity>
            }
            { props.package.packageIntname && props.package.packageIntname.toLowerCase().indexOf('tulpen') === -1 && !props.kaart2 && 
                <TouchableOpacity testID='yesKaartje' onPress={() => props.setKaart2(true)} style={{ display: 'flex', flexDirection: 'row', marginTop: mobileView ? 25 : 25, alignItems: 'center', maxWidth: '100%' }}>
                    <input type="checkbox" id="kaart2" style={{ marginLeft: 20, marginRight: 10 }} name="kaart" defaultChecked={props.kaart2} />
                    <MakeText style={{ fontSize: 16, width: 360 }} fontFamily='Open Sans'>Of voeg een bijzondere wenskaart toe + {EURO(2.5)}</MakeText>
                </TouchableOpacity>
            }
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {
                (props.kaart1 || props.kaart2) &&
                [
                    <MakeText
                        style={{fontSize: mobileView ? 18 : 24, marginTop: mobileView ? 25 : 25, marginBottom: mobileView ? 10 : 10, zIndex: props.kaartText && props.kaartText.length > 0 ? -1 : 1}}
                        fontFamily='Arno Pro'>{props.kaart2 ? `Schrijf hier je mooie woorden... en klik op een kaart` : 'Schrijf hier je mooie woorden...'}
                    </MakeText>,
                    <textarea data-testId='kaartje' name="kaartje" cols={mobileView ? 50 : 60} rows={6} value={props.kaartText} style={{fontSize: 15, fontFamily: 'Open Sans'}} onChange={(e) => props.setText(e.target.value.slice(0, maxChars))}></textarea>,
                    <MakeText style={{fontSize: 15, marginTop: -25}} fontFamily='Open Sans'>{maxChars - props.kaartText.length} karakters over</MakeText>
                ]
            }
        </div>
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', margin: !props.kaart2 ? '20px 0 20px 0' : '-40px 0 20px 0', width: '100%', justifyContent: 'space-between' }}>
                {props.prevPage ?
                    <Button
                        style={{width: 120, marginRight: mobileView ? '38px' : '0', marginTop: mobileView ? '50px' : '60px', marginBottom: '20px'}}
                        textStyle={{fontSize: 18, height: 40, fontFamily: 'Open Sans'}}
                        text={'Vorige'}
                        color={'greenWhite'}
                        onPress={() => (props.prevPage as any)()}
                    /> :
                    <div />
                }
                <Button
                    testID='nextPage'
                    style={{width: 120, marginRight: mobileView ? '38px' : '0', marginTop: mobileView ? '50px' : '60px', marginBottom: '20px'}}
                    textStyle={{fontSize: 18, height: 40, fontFamily: 'Open Sans'}}
                    disabled={(props.kaart1 === undefined && props.kaart2 === undefined) || (props.kaart2 && props.wishcardCode === '')}
                    text={'Volgende'}
                    color={'greenWhite'}
                    onPress={() => props.nextPage()}
                />
                
            </div>
            { (props.kaart2 && !mobileView) &&
                <Pagination
                    activePage={props.activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={wishcardArr.length}
                    pageRangeDisplayed={8}
                    onChange={(page) => props.onChange(page)}
                />
            }
            { (props.kaart2 && !mobileView) &&
                <div style={{width: '100%', height: '170px', display: 'grid', margin: '80px 0 20px 0', gridTemplateColumns: 'auto auto auto auto', gridGap: '32px'}}>
                    { wishcardArr.map((wishcard, i) => {
                        if (i >= startIndexWishcards && i < startIndexWishcards + itemsCountPerPage) {
                            return wishcard.wishcardCode === props.wishcardCode ?
                            (<div className={'image ' + i.toString()} style={{border: wishcard.wishcardCode === props.wishcardCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '90px', height: '90px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: 'url(https://websyn.nl/img/' + wishcard.wishcardCode + '.jpeg)', textAlign: 'left'}}>
                                <h5 style={{display: 'inline', position: 'absolute', margin: checkIndexIsEven(i) ? '-25px 10px 0px 18px' : '100px 10px 0px 18px', fontFamily: 'Open Sans', fontSize: 13, maxWidth: '10em', fontWeight: 200, zIndex: 3}}>{wishcard.name}</h5>
                            </div>) :
                            (<div
                                className={'image ' + i.toString()}
                                style={{border: wishcard.wishcardCode === props.wishcardCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '90px', height: '90px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: 'url(https://websyn.nl/img/' + wishcard.wishcardCode + '.jpeg)', textAlign: 'left'}}
                                onClick={() => props.setKaartCode(wishcard.wishcardCode)}
                            >
                                <h5 style={{display: 'inline', position: 'absolute', margin: checkIndexIsEven(i) ? '-25px 10px 0px 18px' : '100px 10px 0px 18px', fontFamily: 'Open Sans', fontSize: 13, maxWidth: '10em', fontWeight: 200, zIndex: 3}}>{wishcard.name}</h5>
                            </div>
                            )
                        }
                        
                        
                    })}
                </div>
            }
            { (props.kaart2 && mobileView) &&
                <div style={{display: 'grid', margin: '0 100px 20px 100px', gridTemplateColumns: 'auto auto auto auto', gridGap: '32px'}}>
                    { wishcardArr.map((wishcard, i) => {
                        return wishcard.wishcardCode === props.wishcardCode ?
                            (<div className={'image ' + i.toString()} style={{border: wishcard.wishcardCode === props.wishcardCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '45px', height: '45px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: 'url(https://websyn.nl/img/' + wishcard.wishcardCode + '.jpeg)', textAlign: 'left'}}>
                                <h5 style={{display: 'inline', position: 'absolute', margin: checkIndexIsEven(i) ? '-15px 10px 0px 5px' : '45px 10px 0px 5px', fontFamily: 'Open Sans', fontSize: 13, maxWidth: '10em', fontWeight: 200, zIndex: 3}}>{wishcard.name}</h5>
                            </div>) :
                            (<div
                                className={'image ' + i.toString()}
                                style={{border: wishcard.wishcardCode === props.wishcardCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '45px', height: '45px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: 'url(https://websyn.nl/img/' + wishcard.wishcardCode + '.jpeg)', textAlign: 'left'}}
                                onClick={() => props.setKaartCode(wishcard.wishcardCode)}
                            >
                                <h5 style={{display: 'inline', position: 'absolute', margin: checkIndexIsEven(i) ? '-15px 10px 0px 5px' : '45px 10px 0px 5px', fontFamily: 'Open Sans', fontSize: 13, maxWidth: '10em', fontWeight: 200, zIndex: 3}}>{wishcard.name}</h5>
                            </div>)
                    })}
                </div>
            }
        </div>
    </div>
    );
}
kaartjeModal.displayName = 'KaartjeModal';
export default kaartjeModal