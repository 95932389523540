import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import MakeText from '../../../Common/MakeText/MakeText';
import HamburgerLink from './Link';
import Storage from './../../Storage/Storage';

import ShoppingCart from './../../../ShoppingCart/ShoppingCart';
import Logo from './../../../Common/Logo';
import NewOrderProcess from './../../OrderProcess/NewOrderProcess.web';

import { SceneView } from "@react-navigation/core";
import globalStyles from './../../../../Styles/styles';
import { routingName } from '../../OrderProcess/NewOrderProcess.web';

// The store
import { connect } from 'react-redux';
import { calculateMargin, orderProcessMobile, SHOPPING_CART_HEIGHT, smallNavbarLogos } from '../../../../Util/screen/HandleLayout';
import TouchableOpacity from '../../TouchableOpacity/TouchableOpacity';
import USPNavbar from './USPNavbar';
import config from '../../../../config/config.selector';
import { isLoggedIn } from '../../../../API/Http/Requests';


class Navbar extends React.PureComponent<any> {

	storage = new Storage;

	state = {
        sceneDisplay: 'flex',
        linkDisplay: 'none',
		loggedIn: false,
    }

    async componentDidMount() {
        try {
            const loggedIn = await isLoggedIn();
            this.setLoggedIn(loggedIn);
        } catch {}
    }

    async componentDidUpdate() {
        try {
            const loggedIn = await isLoggedIn();
            this.setLoggedIn(loggedIn);
        } catch {}
    }

	setLoggedIn(loggedIn: boolean) : void {
		this.setState({loggedIn});
	}

    render() {

        const { descriptors, navigation } = this.props;
        const activeKey = navigation.state.routes[navigation.state.index].key;
        const descriptor = descriptors[activeKey];

        const pos = 'relative';

        const scrollSquareSide = Math.sqrt(2 * Math.pow(40, 2));

        const smallLogos = smallNavbarLogos(this.props.width);

        const styles = StyleSheet.create({

            sidenav: {
                backgroundColor: 'black',
                opacity: 0.4,
                width: this.props.width,
                height: this.props.height,
            },
            link: {
                alignItems: 'center',
            },
            sceneView: {
                display: this.state.sceneDisplay as any,
            },
            topBar: {
                position: pos,
                top: 0,
                left: 45,
                right: 55,
                bottom: 0,
				// The amount of margin to the right is the left + right position when it's in position: absolute.
				// Meaing: if position is absolute, and letf = 45, right = 55, then marginRight = 100 when position = relative.
				marginRight: pos === 'relative' ? 100 : 0,
				marginBottom:  pos === 'relative' ? 10 : 0,
            },
            navBar: {
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginVertical: 10,
                marginHorizontal: smallLogos ? 10 : 20
            },
            headerText: {
                position: 'absolute',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                // textAlign: 'right',
                margin: calculateMargin(this.props.width),
                height: this.props.height / 10 * 9 - 2 * calculateMargin(this.props.width),
                width: this.props.width - 2 * calculateMargin(this.props.width),
            },
            scrollGraphic : {
                zIndex: this.props.product.products.length === 0 ? 1 : 0,
                position: 'absolute',
                top: this.props.height / 10 * 9 + calculateMargin(this.props.width) / 2 - Math.max((scrollSquareSide / 1600) * this.props.width, scrollSquareSide) / 2,
                transform: [{rotate: '45deg'}],
                backgroundColor: 'white',
                height: Math.max((scrollSquareSide / 1600) * this.props.width, scrollSquareSide),
                width: Math.max((scrollSquareSide / 1600) * this.props.width, scrollSquareSide),
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center'
            },
            scrollText: {
                transform: [{rotate: '-45deg'}],
                fontSize: 12,
                letterSpacing: 0.06,
                color: '#7E918D',
            },
            mand: {
                height: 30,
                width: 30
            }
        });

		let displayOrderDrawer : boolean = false;
		if(this.props.product.products.length || this.props.product.maxHeight === SHOPPING_CART_HEIGHT) displayOrderDrawer = true;
        const conf = config as any;

        return (
            <View style={{overflow: 'hidden'}}>
                <View style={styles.topBar}>
                    <View style={styles.navBar}>

                        <View style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}} >
                            <TouchableOpacity route='/home' onPress={() => {}} >
                                <Logo black color={'black'} small={smallLogos}/>
                            </TouchableOpacity>
                        </View>

						{ process.env.REACT_APP_NODE_ENV === "development" &&
							<View style={{alignSelf: 'center'}}>
								<MakeText style={{fontSize: smallLogos ? 10 : 30, color: 'white', backgroundColor: 'red', padding: 5, borderRadius: 5}}>DEVELOPMENT</MakeText>
							</View>
						}

                        {/* routingName === "amsterdam" &&
							<div className='row alignCenter mt-2' style={{marginLeft: 10}}>
                                <img src='/images/logo_amsterdam.png' height={smallLogos ? 25 : 50} width={smallLogos ? 80 : 160} />
							</div>
                        */}

                        { routingName !== "consumersite" && window.location.pathname.indexOf('/s/') > -1 &&
							<div className='row alignCenter mt-2' style={{marginLeft: 10}}>
                                <img src={routingName === "amsterdam" || routingName === "bloemist" ? `/portals/logo_amsterdam_leeg.png` : `/portals/logo_${window.location.pathname.split('/')[2]}.png`} height={smallLogos ? 25 : 50} width={smallLogos ? 'auto' : 'auto'} />
							</div>
						}

                        <View style={globalStyles.row}>
                            <View style={[globalStyles.row, styles.link, {marginTop: 10}]} testID='hamburgerMenuButton'>
                                {conf.navbarItems.map(item =>
                                    (!item.loginOnly || this.state.loggedIn) ?
                                    <HamburgerLink testID={item.testID} key={`hamburgerlink-${item.route}`} route={item.route} name={item.navigation} text={item.text} navigation={this.props.navigation} />
                                    : undefined
                                )}
                            </View>
                            <TouchableOpacity testID='navbarAccount' onPress={() => this.props.navigation.navigate('Account')}>
                                <img src={'/images/account_circle_24px.svg'} style={{ position: 'relative', top: 6, height: 30, width: 30}}/>
                            </TouchableOpacity>
                            {(!conf.loginOnly || this.state.loggedIn) &&
                                <ShoppingCart navigate={this.props.navigation.navigate} width={this.props.width} height={this.props.height}/>
                            }
                        </View>
                    </View>
                </View>
                <USPNavbar />

                <View style={[styles.sceneView, globalStyles.flex1, globalStyles.alignCenter]}>

                    {/* The page is rendered inside the SceneView */}
                    <SceneView
                        component={descriptor.getComponent()}
                        navigation={descriptor.navigation}
                    />

                </View>

				{ (displayOrderDrawer && !orderProcessMobile(this.props.width, this.props.height)) &&
					<View style={{position: 'fixed' as any, bottom: 0, alignSelf: 'center', maxHeight: '95%'}}>
						<NewOrderProcess mainNavigation={this.props.navigation} />
					</View>
				}

            </View>
        );
    }
};

const mapStateToProps = (state: any) => {
    const { product, app } = state
    return { product, app }
};

export default connect(mapStateToProps)(Navbar);
