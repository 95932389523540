import * as React from 'react';
import validator from 'validator';
import {addLocationToMe, getMe} from "../../API/Http/Requests";

// import { DataService } from './../../API/DataService';
import { ILocation, shippingCountries } from './../../API/IDataService';
import checkZipCode from './../../Util/validation/ZipCodeChecker';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import { amsterdamFactuurAdres } from './4-PickAddress';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

export default class DeliveryAddress extends React.Component<any> {

	state ={
        // navigation params may contain instruction to create an adress for shipping or billing specifically, this will be stored in this field
        billingOrDelivery: undefined,

		billingAndDelivery: false,

		deliveryAddress: {
			id: undefined,
	        postalcode: '',
	        street: '',
			housenumber: '',
			addition: '',
	        city: '',
			fname: '',
			lname: '',
			country: 'NL',
			phone: '',
			isPublicLocation: true,
			housenumbers: [''],
			additions: [''],
	    },
		billingAddress: {
			id: undefined,
	        postalcode: '',
			street: '',
			addition: '',
	        housenumber: '',
	        city: '',
			fname: '',
			lname: '',
			country: 'NL',
			phone: '',
			isPublicLocation: true,
		},
		haveAccount: false,
		deliveryNote: '',

		errorText: '',
		checkingZipShip: false,
		checkingZipBill: false,
		zipCheckShippingStatus: undefined,
		zipCheckBillingStatus: undefined,
		zipCodeWarningShip: undefined,
		zipCodeWarningBill: undefined,
		autoCompletedShip: false,
		autoCompletedBill: false,
	}

	async componentDidMount() {
		GoogleAnalytics.getInstance().hit(this.props.navigation.state);
		this.props.store.setActiveStep('pickDeliveryAddress');

		// Set fname and last name if possible
		try {
			const me = await getMe();
			const deliv = this.state.deliveryAddress;
			const bill = this.state.billingAddress;
			deliv.fname = me.firstName;
			deliv.lname = me.lastName;
			bill.fname = me.firstName;
			bill.lname = me.lastName;
			this.setState({deliveryAddress: deliv, billingAddress: bill, haveAccount: me.account, deliveryNote: this.props.store.orderProcess.deliveryNote});
		} catch {}
	}

	componentWillMount() {
		this.setState({deliveryAddress: Object.assign(this.props.store.orderProcess.deliveryAddress, {country: 'NL'})});
	}

	nextPage() {
		this.setState({errorText: ''});

		this.props.store.setDeliveryNote(this.state.deliveryNote);

		let deliverylocation : ILocation = this.createLocation(this.state.deliveryAddress);

		if(!this.verifyInput(deliverylocation)) return this.setErrorText();

		// If there is seperate billing address, send one by one.
		if(this.state.billingAndDelivery) {
			let billingLocation : ILocation = this.createLocation(this.state.billingAddress);
			billingLocation.invoice = true;

			if(!this.verifyInput(billingLocation)) return this.setErrorText();

			this.sendLocationToBackend(billingLocation, (result : ILocation) => {
				billingLocation.id = result.id;
				this.props.store.setBillingAddress(billingLocation);

				deliverylocation.invoice = false;
				this.sendLocationToBackend(deliverylocation, (result : ILocation) => {
					deliverylocation.id = result.id;
					this.props.store.setDeliveryAddress(deliverylocation);
					this.props.navigation.navigate('payment');
				});
			});
		}
		// If shipping and delivery are the same, send only one address.
		else {
			deliverylocation.invoice = false;
			this.sendLocationToBackend(deliverylocation, result => {
				deliverylocation.id = result.id;
				this.props.store.setDeliveryAddress(deliverylocation);
				const billingLocation = Object.assign({}, deliverylocation, {id: undefined, invoice: true} as {id: number|undefined, invoice: boolean});
				this.sendLocationToBackend(billingLocation, (result : ILocation) => {
					billingLocation.id = result.id;
					this.props.store.setBillingAddress(billingLocation);
					this.props.store.setBillingAddress(routingName === 'amsterdam' ? amsterdamFactuurAdres : billingLocation)
					this.props.navigation.navigate('payment');
				});
			});
		}
	}

	// Send a new location object to the backend.
	sendLocationToBackend(location: any, callback: Function) {
		addLocationToMe(location).then(result => {
			return callback(result);
		}).catch( (e) => {
			console.log(e);
			this.setState({errorText: 'Het lijkt erop dat er iets fout gaat. Excuses voor het ongemak! Probeer het later opnieuw.'})
		});
	}

	// Remove the loading wheel and set an error text for the user.
	setErrorText() : void {
		return this.setState({errorText: 'Een of meerdere van de velden zijn niet correct ingevuld. Alle velden zijn verplicht.'});
	}

	/**
	 * Takes ILocation object.
	 * Checks if all required fields have a value.
	 * Returns true if so, false if not.
	 *
	 * @param  location The location for which input is checked.
	 * @return true or false, based on wether the check succeeds.
	 */
	verifyInput(location: ILocation) : boolean {
		if(validator.isLength(location.city, {min: 1, max: 128})
			&& validator.isLength(location.street, {min: 1, max: 128})
			&& validator.isLength(location.housenumber, {min: 1, max: 128})
			&& validator.isLength(location.postalcode, {min: 1, max: 128})
			&& validator.isLength(location.fname, {min: 0, max: 128})
			&& validator.isLength(location.lname, {min: 1, max: 128})
			&& (routingName !== 'amsterdam' || validator.isLength(location.phone, {min: 6, max: 16}))
		) return  true;

		return false;
	}

	/**
	 * Creates a location that can be send and saved by the backend.
	 * @return returns a location based on data in the state of the class.
	 */
	createLocation(address: ILocation) : ILocation {
		let location: ILocation = {
			street: address.street,
			housenumber: address.housenumber,
			addition: address.addition,
			postalcode: address.postalcode.replace(' ', ''),
			city: address.city,
			country: address.country,
			fname: address.fname,
			lname: address.lname,
			phone: address.phone,
			isPublicLocation: address.isPublicLocation
		}

		return location;
	}

	onChange(name: string, value: string, billing?: boolean) : void {
		let autoCompleteKey = billing ? 'autoCompletedBill' : 'autoCompletedShip';
		let autoCompleteValue;
		if (name === 'street' || name === 'city') {
			autoCompleteValue = false;
		} else {
			autoCompleteValue = this.state[autoCompleteKey]
		}
		let tmpState : ILocation;

		if(billing) tmpState = Object.assign({}, this.state.billingAddress);
		else  tmpState = Object.assign({}, this.state.deliveryAddress);

		tmpState[name] = value;

		if (name === 'postalcode' || name === 'housenumber' || name === 'addition') {
			if (name === 'postalcode') {
				tmpState['housenumber'] = '';
				tmpState['addition'] = '';
				tmpState['housenumbers'] = [''];
				tmpState['additions'] = [''];
			}
			if (name === 'housenumber') {
				tmpState['addition'] = '';
				tmpState['additions'] = [''];
			}
			name === 'postalcode' ? this.checkZipCodeShip(value) : this.checkZipCodeShip(this.state.deliveryAddress.postalcode);
		}

		if(billing) this.setState({billingAddress: tmpState, [autoCompleteKey]: autoCompleteValue});
		else this.setState({deliveryAddress: tmpState, [autoCompleteKey]: autoCompleteValue});
	}

	checkZipCodeShip(pc) {
		if (pc === '') { return;	}
		this.setState({checkingZipShip: true});
		setTimeout(async () => {
			const foundLoc = await checkZipCode(this.state.deliveryAddress);
			if (foundLoc === undefined) {
				this.setState({zipCheckShippingStatus: 'Even check dubbel check - heb je het juiste bezorgadres ingevoerd?', checkingZipShip: false, autoCompletedShip: false,});
				return;
			}
			foundLoc.housenumbers = foundLoc.housenumbers && foundLoc.housenumbers[0] ? [`${foundLoc.housenumbers.join('/')} - welke?`] : this.state.deliveryAddress.housenumbers;
			foundLoc.additions = foundLoc.additions && foundLoc.additions[0] ? [`${foundLoc.additions.join('/')} - welke?`] : this.state.deliveryAddress.additions;

			this.setState({
				deliveryAddress: Object.assign(this.state.deliveryAddress, foundLoc),
				checkingZipShip: false,
				zipCheckShippingStatus: undefined,
				autoCompletedShip: true,
			});
		}, 0);
	}

	checkZipCodeBill() {
		if (this.state.billingAddress.postalcode === '' || this.state.billingAddress.housenumber === '') {
			return;
		}
		this.setState({checkingZipBill: true});
		setTimeout(async () => {
			const foundLoc = await checkZipCode(this.state.billingAddress);
			if(foundLoc === undefined) {
				this.setState({zipCheckBillingStatus: 'Even check dubbel check - heb je het juiste factuuradres ingevoerd?', checkingZipBill: false, autoCompletedBill: false});
				return;
			}
			this.setState({
				billingAddress: Object.assign(this.state.billingAddress, foundLoc),
				checkingZipBill: false,
				zipCheckBillingStatus: undefined,
				autoCompletedBill: true,
			});
		}, 0);
	}

	checkPostalCode(billing: boolean) {
		const postalCode = !billing ? this.state.deliveryAddress.postalcode.replace(' ', '') : this.state.billingAddress.postalcode.replace(' ', '');
		const stateKey = !billing ? 'zipCodeWarningShip' : 'zipCodeWarningBill';
		if (new RegExp(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/, 'i').test(postalCode)) {
			this.setState({[stateKey]: undefined});
		} else {
			this.setState({
				[stateKey]: 'Deze postcode wordt niet herkend.'
			})
		}
	}

    render() {
        // Go back to overview page if the totalprice is 0. This scenario happens when a user refreshes the page on this screen.
		if(this.props.store.orderProcess.totalPrice === 0) {
			this.props.navigation.navigate('overview');
		}

		const mobileView = this.props.mobileView;
		const largeText = mobileView ? 24 : 36;
		// const mediumText = mobileView ? 18 : 24;
		const smallText = 15;
		const warningColor = '#FF4500';
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', height: '95%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-around', width: '100%', }}>
                    {/* Left block */}
                    <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' : 475, padding: mobileView ? '5%' : undefined}}>
                        <MakeText style={{fontSize: largeText, alignSelf: 'center'}} fontFamily={'Arno Pro'}>Bezorgadres</MakeText>

						{/* Voor amsterdam portal, laat naam locatie zien ipv naam. Naam wordt opgeslage in het lname veld */}
                        { process.env.REACT_APP_NODE_ENV === "portal"  ?
							<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
								{/* First name */}
								<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
									<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Naam</MakeText>
									<input data-testid='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.lname} onChange={(e) => this.onChange('lname', e.target.value)}></input>
								</div>
								{/* Phone number */}
								<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
									<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Telefoonnummer</MakeText>
									<input data-testid='phone' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.phone} onChange={(e) => this.onChange('phone', e.target.value)}></input>
								</div>
							</div>
							:
							<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
								{/* First name */}
								<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
									<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Voornaam</MakeText>
									<input data-testid='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.fname} onChange={(e) => this.onChange('fname', e.target.value)}></input>
								</div>
								{/* Last name */}
								<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
									<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Achternaam</MakeText>
									<input data-testid='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.lname} onChange={(e) => this.onChange('lname', e.target.value)}></input>
								</div>
							</div>
						}

                        {/* Postcode en nummer */}
                        {/* Postcode en nummer */}
                    <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                        {/* Postcode */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '100%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Postcode</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.postalcode}
								onChange={(e) => {
									this.onChange('postalcode', e.target.value);
								}}
								onBlur={(e) => {
									this.checkPostalCode(false);
									this.checkZipCodeShip(e.target.value);
								}}
							></input>
                        </div>
					</div>
					<div style={{display: 'flex', flexDirection: mobileView ? 'row' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '100%', height: '60px'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Huisnummer</MakeText><MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>{}</MakeText>
                            <textarea style={{fontSize: smallText, padding: 5, height: 'auto'}}
								rows={this.state.deliveryAddress.housenumbers && this.state.deliveryAddress.housenumbers.length > 55 ? 1 : 5}
								placeholder={`${this.state.deliveryAddress.postalcode && this.state.deliveryAddress.postalcode.length > 5 && this.state.deliveryAddress.housenumbers && this.state.deliveryAddress.housenumbers[0] ? this.state.deliveryAddress.housenumbers[0] : ''}`}
								value={this.state.deliveryAddress.housenumber}
								onBlur={() => {
									this.checkZipCodeShip(this.state.deliveryAddress.postalcode);
								}}
								onChange={(e) => {
									this.onChange('housenumber', e.target.value);
								}}
							></textarea>
                        </div>
					</div>
					<div>
						<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '100%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Huisnummer toevoeging</MakeText><MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>{}</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}}
								placeholder={`${this.state.deliveryAddress.postalcode && this.state.deliveryAddress.postalcode.length > 5 && this.state.deliveryAddress.additions && this.state.deliveryAddress.additions[0] ? this.state.deliveryAddress.additions[0] : ''}`}
								value={this.state.deliveryAddress.addition}
								onBlur={() => {
									this.checkZipCodeShip(this.state.deliveryAddress.postalcode);
								}}
								onChange={(e) => {
									this.onChange('addition', e.target.value);
								}}
							></input>
                        </div>
                    </div>
                        {/* Straat */}
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Straat</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.street} onChange={(e) => this.onChange('street', e.target.value)}></input>
                        </div>
						<div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
							{/* City */}
							<div style={{display: 'flex', flexDirection: 'column', width: '80%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Stad</MakeText>
								<input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.city.replace('&#x27;', "'")} onChange={(e) => this.onChange('city', e.target.value)}></input>
							</div>
							{/* Country */}
							<div style={{display: 'flex', flexDirection: 'column', width: '20%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Land</MakeText>
								<select style={{fontSize: smallText, padding: 5}} onChange={(e) => this.onChange('country', e.target.value)}>
									{Object.values(shippingCountries).map(c => <option value={c}>{c}</option>)}
								</select>
							</div>
						</div>
                        {/* Checkbox */}
						{/* routingName === 'consumersite' &&
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
								<input type='checkbox' checked={this.state.billingAndDelivery} onChange={(e) => this.setState({billingAndDelivery: e.target.checked})}></input>
								<MakeText style={{fontSize: smallText, marginLeft: 10}}>Ik wil een ander factuuradres gebruiken</MakeText>
							</div>
						*/}
						{ process.env.REACT_APP_NODE_ENV === "portal" &&
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
								<input type='checkbox' checked={!this.state.deliveryAddress.isPublicLocation} onChange={(e) => this.setState({deliveryAddress: Object.assign(this.state.deliveryAddress, {isPublicLocation: !e.target.checked})})}></input>
								<MakeText style={{fontSize: smallText, marginLeft: 10}}>Dit is een privé adres</MakeText>
							</div>
						}
						{this.state.zipCheckShippingStatus &&
							<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, color: warningColor, marginTop: 10}}>{this.state.zipCheckShippingStatus}</MakeText>
						}
                    </div>
                    {/* Right block */}
                    {this.state.billingAndDelivery && routingName === 'consumersite' &&
                    <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' : 475, padding: mobileView ? '5%' : undefined}}>
                        <MakeText style={{fontSize: largeText, alignSelf: 'center'}} fontFamily={'Arno Pro'}>Factuuradres</MakeText>
                        {/* Name */}
                        <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                            {/* First name */}
                            <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                                <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Voornaam</MakeText>
                                <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.billingAddress.fname} onChange={(e) => this.onChange('fname', e.target.value, true)}></input>
                            </div>
                            {/* Last name */}
                            <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                                <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Achternaam</MakeText>
                                <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.billingAddress.lname} onChange={(e) => this.onChange('lname', e.target.value, true)}></input>
                            </div>
                        </div>
                        {/* Postcode en nummer */}
                        <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                            {/* Postcode */}
                            <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                                <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Postcode</MakeText>
                                <input type="text" style={{fontSize: smallText, padding: 5}}
									value={this.state.billingAddress.postalcode}
									onBlur={() => {
										this.checkPostalCode(true);
										this.checkZipCodeBill();
									}}
									onChange={(e) => this.onChange('postalcode', e.target.value, true)}
								></input>
                            </div>
                            {/* Huisnummer */}
                            <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                                <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Huisnummer</MakeText>
                                <input type="text" style={{fontSize: smallText, padding: 5}}
									value={this.state.billingAddress.housenumber}
									onBlur={() => this.checkZipCodeBill()}
									onChange={(e) => this.onChange('housenumber', e.target.value, true)}
								></input>
                            </div>
                        </div>
                        {/* Straat */}
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Straat</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.billingAddress.street} onChange={(e) => this.onChange('street', e.target.value, true)}></input>
                        </div>
						<div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
							{/* City */}
							<div style={{display: 'flex', flexDirection: 'column', width: '80%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Stad</MakeText>
								<input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.billingAddress.city.replace('&#x27;', "'")} onChange={(e) => this.onChange('city', e.target.value, true)}></input>
							</div>
							{/* Country */}
							<div style={{display: 'flex', flexDirection: 'column', width: '20%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Land</MakeText>
								<select style={{fontSize: smallText, padding: 5}} onChange={(e) => this.onChange('country', e.target.value, true)}>
									{Object.values(shippingCountries).map(c => <option value={c}>{c}</option>)}
								</select>
							</div>
						</div>
						{this.state.zipCheckBillingStatus &&
							<MakeText fontFamily={'Open Sans'} style={{fontSize: 15, color: warningColor, marginTop: 10}}>{this.state.zipCheckBillingStatus}</MakeText>
						}
                    </div>
                    }
                </div>

                {/* Error text */}
                {this.state.errorText && <MakeText fontFamily={'Open Sans'} style={{fontSize: smallText, color: 'red', marginTop: 10, marginBottom: 25, alignSelf: 'flex-end'}}>{this.state.errorText}</MakeText>}

				<div className='col' style={{width: '100%', alignItems: 'center'}}>
					<MakeText style={{fontSize: 16, marginTop: 15, marginBottom: 5}}>Opmerking voor de bezorger</MakeText>
					<input type='text' maxLength={50} value={this.state.deliveryNote} style={{fontSize: 15, padding: 5, width: mobileView ? '90%' : 400}} onChange={(e) => this.setState({deliveryNote: e.currentTarget.value})} />
				</div>
				<div style={{width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginBottom: mobileView ? 25 : 0, marginTop: mobileView ? 50 : 0}}>
					<Button onPress={() => this.props.navigation.navigate('pickLogin')} color={'greenWhite'} text={'Terug'} textStyle={{fontSize: mobileView ? 15 : 18, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginLeft: mobileView ? 25 : 0}} />
					<Button testID='nextPage' style={{marginRight: mobileView ? 25 : 0}} textStyle={{fontSize: smallText, fontFamily: 'Open Sans', height: mobileView ? undefined : 50}} text={'Naar afrekenen'} onPress={() => this.nextPage()} color={'greenWhite'} />
				</div>
            </div>
        );
    }
}
